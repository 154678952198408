import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { collection, doc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { firestore, functions } from "../../firebase";
import { getLocalTime } from "../../helpers/time";
import { InspirationMetadataViewer } from "./InspirationInfo";

const PublishInspiration = ({ inspiration }) => {
  const inspirationId = inspiration.id;
  const inspirationEventReference = useMemo(
    () =>
      doc(
        collection(firestore, "sharedInspirationEvents"),
        `inspiration_${inspirationId}`,
      ),
    [inspirationId],
  );

  const [inspirationEvent, loadingInspirationEvent] = useDocumentData(
    inspirationEventReference,
  );

  if (loadingInspirationEvent) return null;

  const handlePublish = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!confirm(`Publish "${inspiration.title}" to all users?`)) return;

    (async () => {
      try {
        await functions.httpsCallable("processInspirationCommand")({
          command: "PublishInspiration",
          params: { inspirationId },
        });
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      }
    })();
  };

  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
      <h2 className="text-lg font-semibold mb-2">The edit</h2>
      {inspirationEvent ? (
        <div>
          Published to <em>The edit</em> at{" "}
          {inspirationEvent.time?.date
            ? getLocalTime(inspirationEvent.time).format("YYYY-MM-DD HH:mm")
            : ""}
          .
        </div>
      ) : (
        <>
          <div>
            Current status: not yet published to <em>The edit</em>.
          </div>
          <button
            type="button"
            className="bg-regularButtonBackgroundColor mt-3 py-2 rounded w-full text-sm font-bold"
            onClick={handlePublish}
          >
            Publish to all users now
          </button>
        </>
      )}
    </div>
  );
};

const DuplicateInspiration = ({ inspiration }) => {
  const navigate = useNavigate();

  const handleDuplicate = () => {
    (async () => {
      try {
        const response = await functions.httpsCallable(
          "processInspirationCommand",
        )({
          command: "DuplicateInspiration",
          params: { inspirationId: inspiration.id },
        });
        navigate(`/inspiration/${response.data}?draft`);
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      }
    })();
  };
  return (
    <button
      type="button"
      className="bg-cardBackgroundColor mt-3 py-2 rounded w-full text-sm font-bold"
      onClick={handleDuplicate}
    >
      Duplicate and Go To Editor
    </button>
  );
};

export const InspirationPageItemInfo = ({ selectedInspiration }) => {
  return (
    <div className="flex-grow p-3 overflow-y-auto ">
      {selectedInspiration?.id ? (
        <>
          <InspirationMetadataViewer inspiration={selectedInspiration} />
          <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
            <Link to={`/inspiration/${selectedInspiration.id}?draft`}>
              <button
                type="button"
                className="py-2 rounded w-full text-sm font-bold bg-regularButtonBackgroundColor text-textColor"
              >
                Go to editor
              </button>
            </Link>
          </div>
          <PublishInspiration inspiration={selectedInspiration} />
          <DuplicateInspiration inspiration={selectedInspiration} />
        </>
      ) : (
        <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
          No inspiration selected...
        </div>
      )}
    </div>
  );
};
