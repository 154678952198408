import {
  getDateTimeFromLocalTime,
  originTextToString,
  TransportationCard as TransportationCardType,
} from "@origin-dot/core";
import { DateTime } from "luxon";

import { CardContainer } from "../common/CardContainer";
import { Icon } from "../common/Icon";
import { OriginText } from "../common/OriginText";
import type { CardComponentProps, Stylable } from "../helpers/types";

type LegProps = Stylable<
  TransportationCardType["info"]["departure"] & { position: "top" | "bottom" }
>;

const Leg = ({ className, position, time, title, subTitle }: LegProps) => {
  const dateTime = getDateTimeFromLocalTime(time);

  return (
    <div
      className={className}
      css={{
        backgroundColor: "var(--cardElevatedSecondaryColor)",
        borderRadius: 6,
        display: "flex",
        alignItems: "center",
        padding: 16,
      }}
    >
      <div
        css={{
          alignSelf: "stretch",
          position: "relative",
          width: 12,
          marginRight: 16,
        }}
      >
        <div
          css={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: -6,
            marginTop: -6,
            width: 12,
            height: 12,
            borderRadius: 6,
            backgroundColor: "var(--generalTintColor)",
            marginRight: 16,
          }}
        />

        <div
          css={[
            {
              position: "absolute",
              backgroundColor: "var(--generalTintColor)",
              left: "50%",
              marginLeft: -1,
              width: 2,
              height: "200%",
            },
            position === "bottom" ? { bottom: "50%" } : { top: "50%" },
          ]}
        />
      </div>
      <div css={{ flexGrow: 1 }}>
        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <div className="type-subhead">{title.toString()}</div>
          <div className="type-subhead" css={{ fontWeight: 400 }}>
            {dateTime.toLocaleString(DateTime.TIME_SIMPLE)}
          </div>
        </div>
        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <div className="type-subhead">{subTitle?.toString()}</div>
          <div className="type-subhead" css={{ fontWeight: 400 }}>
            {dateTime.toLocaleString(DateTime.DATE_MED)}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TransportationCard = ({
  className,
  icon,
  title,
  departure,
  arrival,
  metadata,
}: CardComponentProps<TransportationCardType>) => {
  const departureDate = getDateTimeFromLocalTime(departure.time);
  const arrivalDate = getDateTimeFromLocalTime(arrival.time);
  const duration = arrivalDate.diff(departureDate, ["hours", "minutes"]);

  const metadataItems = [
    duration.minutes
      ? duration.toFormat("h'h' m'm'")
      : duration.toFormat("h'h'"),
    ...(metadata ?? []).map((s) => originTextToString(s)),
  ];

  // TODO: handle data rows.

  return (
    <CardContainer className={className}>
      <div
        className="text-quarternary"
        css={{ display: "flex", alignItems: "center", marginBottom: 16 }}
      >
        {icon && <Icon name={icon} size={20} css={{ marginRight: 4 }} />}
        {title && <OriginText className="type-caption1Bold" text={title} />}
      </div>
      <Leg position="top" {...departure} />
      <div
        className="type-caption1Regular text-tertiary"
        css={{ margin: "12px 0 12px 44px" }}
      >
        {metadataItems.join(" · ")}
      </div>
      <Leg position="bottom" {...arrival} />
    </CardContainer>
  );
};
