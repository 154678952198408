import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { produce } from "immer";
import moment from "moment-timezone";
import posthog from "posthog-js";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import TextareaAutosize from "react-textarea-autosize";
import { v4 as uuidv4 } from "uuid";

import { faArrowDown as downIcon } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp as upIcon } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faChevronCircleDown as openItemIcon } from "@fortawesome/free-solid-svg-icons/faChevronCircleDown";
import { faChevronCircleRight as closedItemIcon } from "@fortawesome/free-solid-svg-icons/faChevronCircleRight";
import { faPlusCircle as addItemIcon } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTrashAlt as deleteIcon } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Avatar,
  AvatarList,
  Block,
  Icon,
  OriginText,
} from "@origin-dot/components";

import currencyLib from "currency.js";
import { Timestamp, collection, doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { firestore, functions } from "../../firebase";

import { DateRangePicker } from "./DateRangePicker";
import { DateTimePicker } from "./DateTimePicker";
import { Editable } from "./Editable";
import { EmojiPicker } from "./EmojiPicker";
import { ImageUpload } from "./ImageUpload";

import { ActionButton } from "../ActionButton";
import { AirportLookup } from "../AirportLookup";
import { FormattedText } from "../FormattedText";
import { Image } from "../Image";
import { PlaceLookup } from "../PlaceLookup";
import { SegmentedControl } from "../SegmentedControl";

import {
  SelectedBlocksContext,
  SelectedBlocksProvider,
} from "./useSelectedBlocks";

const GalleryAspectContext = React.createContext(undefined);
const TripRequestIdContext = React.createContext(undefined);
const previewGalleryAspect = 4 / 5;

const headerAspectRatio = 1125 / 1960;

const transportationIcons = [
  "plane",
  "car",
  "bus",
  "train",
  "boat",
  "bicycle",
  "walking",
];

const transportationOptions = Object.fromEntries(
  transportationIcons.map((icon) => [
    icon,
    <Icon key={icon} name={icon} size={20} />,
  ]),
);

const locationIcons = [
  "",
  "airport",
  "station",
  "accommodation",
  "activity",
  "foodAndDrinks",
];

const locationOptions = Object.fromEntries(
  locationIcons.map((icon) => [
    icon,
    <Icon key={icon} name={icon || "pin"} size={20} />,
  ]),
);

const currencyOptions = {
  EUR: "€",
  USD: "$",
};

const routeTypeOptions = {
  geodesic: "Geodesic",
  straight: "Straight",
  route: "Route",
};

const mapStyleOptions = {
  standard: "Standard",
  satellite: "Satellite",
  hybrid: "Hybrid",
};

const mapSizeOptions = {
  square: "Square",
  landscape: "Landscape",
  portrait: "Portrait",
};

const headerStyleOptions = {
  main: "Main",
  sub: "Sub",
};

const dividerStyleOptions = {
  full: "Full",
  short: "Short",
};

const clientVisibilityOptions = {
  admin: "Admin Only",
  client: "Client Visible",
};

const bookingVisibilityOptions = {
  always: "Always",
  ready: "Pre-booking",
  booked: "Booked",
};

const sharingVisibilityOptions = {
  safe: "Visible on Share Page",
  unsafe: "Hidden on Share Page",
};

const mapStyles = {
  standard: undefined,
  satellite: { url: "mapbox://styles/mapbox/satellite-v9" },
  hybrid: { url: "mapbox://styles/mapbox/satellite-streets-v11" },
};

const headerStyles = {
  main: {
    style: "main",
    hasTopPadding: undefined,
    hasAdditionalTopPadding: true,
    hasBottomPadding: undefined,
  },
  sub: {
    style: "sub",
    hasTopPadding: undefined,
    hasAdditionalTopPadding: undefined,
    hasBottomPadding: false,
  },
};

const stripUndefinedAndNullInPlace = (data) => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        if (value === undefined || value === null) data.splice(index, 1);
        else stripUndefinedAndNullInPlace(value);
      });
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (value === undefined || value === null) delete data[key];
        else stripUndefinedAndNullInPlace(value);
      }
    }
  }
};

const deepConvertInPlace = (data, convert) => {
  if (typeof data === "object" && data !== null) {
    for (const [key, value] of Object.entries(data)) {
      const converted = convert(value);
      if (converted !== undefined) data[key] = converted;
      else deepConvertInPlace(value, convert);
    }
  }
};

const startsWithDay = /^\s*day\s*[\d#]+\s*(?:-\s*[\d#]+\s*)?:?\s*(.*)/i;

const isTimestamp = (data) =>
  typeof data === "object" && data !== null && "toDate" in data;

export const convertTimestamps = (data) => {
  return produce(data, (draft) => {
    deepConvertInPlace(draft, (convertTimestampsData) =>
      isTimestamp(convertTimestampsData)
        ? convertTimestampsData.toDate().toISOString()
        : undefined,
    );
  });
};
const modify = (value, callback) =>
  produce(value, (draft) => {
    callback(draft);
    stripUndefinedAndNullInPlace(draft);
  });

const availableBlocks = {
  Text: {
    match: (block) => block.type === "Text",
    convert: ({ formattedText, title }) => ({
      type: "Text",
      info: {
        formattedText: formattedText || title || "%CHANGE_ME%",
      },
    }),
  },
  Header: {
    match: (block) => block.type === "Header",
    convert: ({ formattedText, title }) => ({
      type: "Header",
      info: {
        title: title || formattedText || "%CHANGE_ME%",
        style: "sub",
        hasBottomPadding: false,
      },
    }),
  },
  "Section Header": {
    match: (block) =>
      block.type === "Group" && block.info?.kind === "SectionHeader",
    convert: ({
      formattedText,
      title,
      editorParams: { closestSectionHeaderStartDate },
    }) => {
      const momentDate = moment(closestSectionHeaderStartDate);
      const date = momentDate.add(1, "day").format("YYYY-MM-DD");
      return {
        type: "Group",
        info: {
          kind: "SectionHeader",
          blocks: [
            {
              uuid: uuidv4(),
              type: "Divider",
              info: {
                style: "short",
              },
            },
            {
              uuid: uuidv4(),
              type: "DateHeader",
              info: {
                startDate: date,
                endDate: date,
              },
            },
            {
              uuid: uuidv4(),
              type: "Header",
              info: {
                title: title || formattedText || "Day #: %CHANGE_ME%",
              },
            },
          ],
        },
        metadata: {
          automated: true,
        },
      };
    },
  },
  Divider: {
    match: (block) => block.type === "Divider",
    convert: () => ({
      type: "Divider",
      info: {
        style: "full",
      },
    }),
  },
  Callout: {
    match: (block) =>
      block.type === "Card" &&
      block.info &&
      block.info.card &&
      block.info.card.type === "Callout",
    convert: ({ formattedText, title }) => ({
      type: "Card",
      info: {
        card: {
          type: "Callout",
          info: {
            emoji: "💚",
            formattedText: formattedText || title || "%CHANGE_ME%",
            title: formattedText && title,
          },
        },
      },
    }),
  },
  Costs: {
    match: (block) =>
      block.type === "Card" &&
      block.info &&
      block.info.card &&
      block.info.card.type === "Payment",
    convert: ({ formattedText, title }) => ({
      type: "Card",
      info: {
        card: {
          type: "Payment",
          info: {
            currency: "USD",
            amount: 0,
            title: (formattedText && title) || "Additional Costs",
            formattedMessage: formattedText || title || "%CHANGE_ME%",
          },
        },
      },
      metadata: {
        hideFromSharing: true,
      },
    }),
  },
  "In-App Payment": {
    match: (block) => block.type === "InAppPayment",
    convert: ({ formattedText, title }) => ({
      type: "InAppPayment",
      info: {
        currency: "USD",
        amount: 40000,
        title: (formattedText && title) || "Trip costs",
        formattedMessage:
          formattedText || title || "%CHANGE_ME% Based on 2 people traveling.",
        amountLabel: "Total (incl. VAT)",
        buttonText: "%CHANGE_ME% Please finalize the InApp Payment Block",
        paymentId: "%CHANGE_ME%",
        status: "open",
        dueDate: Timestamp.fromDate(
          new Date(Date.now() + 15 * 24 * 60 * 60000),
        ),
      },
      metadata: {
        hideFromSharing: true,
        paymentType: "confirmation",
        confirmationType: "full",
        totalTripAmount: 0,
        description:
          "**Included:**\n- %CHANGE_ME%\n\n**Excluded:**\n- %CHANGE_ME%",
        stripeInvoiceDescription: "Below are the details of your payment",
        stripePaymentDescription: "%CHANGE_ME%",
        tripDepositAmount: 0,
        seeMembershipOption: false,
        membershipTitle: "Scenset membership",
        membershipAmount: 3000,
        membershipDescription: "One year of Scenset membership",
        membershipStripeDescription: "One year of Scenset membership",
        seeTrialOption: true,
        trialTitle: "Trip Planning Fee",
        trialAmount: 400,
        trialDescription: "Scenset Planning Fee",
        trialStripeDescription: "Scenset Planning Fee",
        additionalAmount: 0,
      },
    }),
  },
  "Single Image": {
    match: (block) => block.type === "Photo",
    convert: ({ images }) => ({
      type: "Photo",
      info: {
        image:
          images && images.length > 0
            ? images[0]
            : {
                url: "https://assets.origin.me/construction.jpg",
                width: 720,
                height: 900,
                caption: "",
                attribution: "",
              },
        showsCaption: true,
      },
    }),
  },
  "Image Gallery": {
    match: (block) => block.type === "Gallery",
    convert: ({ images }) => ({
      type: "Gallery",
      info: {
        images: images || [
          {
            url: "https://assets.origin.me/construction.jpg",
            width: 720,
            height: 900,
            attribution: "",
            caption: "",
          },
          {
            url: "https://assets.origin.me/construction.jpg",
            width: 720,
            height: 900,
            attribution: "",
            caption: "",
          },
        ],
        sideScroll: true,
        showCaptions: true,
      },
    }),
  },
  Map: {
    match: (block) => block.type === "RouteMap",
    convert: () => ({
      type: "RouteMap",
      info: {
        mapboxStyle: { url: "mapbox://styles/mapbox/satellite-streets-v11" },
        mapSize: "square",
        legs: [],
        points: [],
      },
    }),
  },
  Transportation: {
    match: (block) =>
      block.type === "Card" &&
      block.info &&
      block.info.card &&
      block.info.card.type === "Transportation",
    convert: ({
      formattedText,
      title,
      editorParams: { closestSectionHeaderStartDate },
    }) => {
      const departureDate = new Date(closestSectionHeaderStartDate);
      departureDate.setMilliseconds(0);
      departureDate.setSeconds(0);
      departureDate.setMinutes(Math.ceil(departureDate.getMinutes() / 10) * 10);

      const arrivalDate = new Date(departureDate.getTime());
      arrivalDate.setHours(arrivalDate.getHours() + 8);
      arrivalDate.setMinutes(arrivalDate.getMinutes() + 5);

      return {
        type: "Card",
        info: {
          card: {
            type: "Transportation",
            info: {
              icon: "plane",
              title: title || formattedText || "%CHANGE_ME% KL 641",
              departure: {
                time: {
                  date: Timestamp.fromDate(departureDate),
                  timeZone: "Europe/Amsterdam",
                },
                title: "Amsterdam",
                subTitle: "AMS",
              },
              arrival: {
                time: {
                  date: Timestamp.fromDate(arrivalDate),
                  timeZone: "America/New_York",
                },
                title: "New York",
                subTitle: "JFK",
              },
              metadata: ["Business class", "KLM"],
              rows: [],
            },
          },
        },
      };
    },
  },
};

const isBlockHiddenFromAdmin = (block) => {
  // Block is hidden from admin
  return (
    block?.metadata?.hideFromSharing &&
    block?.metadata?.hideFromStatuses &&
    block.metadata.hideFromStatuses.includes("READY") &&
    block.metadata.hideFromStatuses.includes("BOOKED")
  );
};

const BlockConverter = ({ value, setValue, editorParams }) => {
  const convert = (converter) => {
    let formattedText;
    if (value?.info) {
      if (value.info.formattedText) formattedText = value.info.formattedText;
      else if (value.info.formattedMessage)
        formattedText = value.info.formattedMessage;
      else if (value.info.card?.info) {
        if (value.info.card.info.formattedText)
          formattedText = value.info.card.info.formattedText;
        else if (value.info.card.info.formattedMessage)
          formattedText = value.info.card.info.formattedMessage;
      }
    }

    let title;
    if (value?.info) {
      if (value.info.title) title = value.info.title;
      else if (value.info.card?.info?.title) title = value.info.card.info.title;
      if (value.type === "Group" && value.info.kind === "SectionHeader") {
        try {
          title = value.info.blocks.find(({ type }) => type === "Header").info
            .title;
          // biome-ignore lint/correctness/noUnusedVariables: ignored
        } catch (err) {
          // Ignore
        }
      }
    }

    let images;
    if (value?.info) {
      if (value.info.image) images = [value.info.image];
      else if (value.info.images) images = value.info.images;
    }

    const newBlock = converter({ formattedText, title, images, editorParams });
    setValue({ ...newBlock, uuid: uuidv4() });
  };

  let bookingVisibility = "always";
  let sharingVisibility = "safe";
  let clientVisibility = "client";

  if (value.metadata) {
    if (value.metadata.hideFromStatuses) {
      if (value.metadata.hideFromStatuses.includes("BOOKED"))
        bookingVisibility = "ready";
      if (value.metadata.hideFromStatuses.includes("READY"))
        bookingVisibility = "booked";
    }
    if (value.metadata.hideFromSharing) sharingVisibility = "unsafe";
    if (isBlockHiddenFromAdmin(value)) {
      clientVisibility = "admin";
    }
  }

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 gap-1">
        {Object.entries(availableBlocks).map(
          ([name, { match, convert: converter }]) => (
            <button
              type="button"
              className={`${
                match(value)
                  ? "bg-textSecondaryColor text-newBackgroundColor border-textSecondaryColor"
                  : "border-searchBorderColor hover:bg-separatorColor"
              } text-xs border rounded`}
              style={{ backgroundOrigin: "padding-box" }}
              key={name}
              onClick={() => convert(converter)}
            >
              {name}
            </button>
          ),
        )}
      </div>
      <div className="flex mt-3">
        <SegmentedControl
          className="flex-grow mr-1"
          options={clientVisibilityOptions}
          value={clientVisibility}
          onChange={(visibility) =>
            setValue(
              modify(value, (draft) => {
                if (!draft.metadata) draft.metadata = {};
                switch (visibility) {
                  case "admin":
                    draft.metadata.hideFromStatuses = ["READY", "BOOKED"];
                    draft.metadata.hideFromSharing = true;
                    break;
                  // case "client":
                  default:
                    draft.metadata.hideFromStatuses = undefined;
                    break;
                }
              }),
            )
          }
        />
      </div>
      {clientVisibility === "client" && (
        <div className="flex mt-3">
          <SegmentedControl
            className="flex-grow mr-1"
            options={bookingVisibilityOptions}
            value={bookingVisibility}
            onChange={(visibility) =>
              setValue(
                modify(value, (draft) => {
                  if (!draft.metadata) draft.metadata = {};
                  switch (visibility) {
                    case "ready":
                      draft.metadata.hideFromStatuses = ["BOOKED"];
                      break;
                    case "booked":
                      draft.metadata.hideFromStatuses = ["READY"];
                      break;
                    default:
                      // case "always":
                      draft.metadata.hideFromStatuses = undefined;
                      break;
                  }
                }),
              )
            }
          />
          <SegmentedControl
            className="flex-grow"
            options={sharingVisibilityOptions}
            value={sharingVisibility}
            onChange={(visibility) =>
              setValue(
                modify(value, (draft) => {
                  if (!draft.metadata) draft.metadata = {};
                  switch (visibility) {
                    case "unsafe":
                      draft.metadata.hideFromSharing = true;
                      break;
                    default:
                      // case "safe":
                      draft.metadata.hideFromSharing = undefined;
                      break;
                  }
                }),
              )
            }
          />
        </div>
      )}
    </div>
  );
};

const EditorIcon = ({ icon, className, onClick, postHogEvent, ...props }) => {
  const handleClick = (event) => {
    if (postHogEvent) {
      posthog.capture(postHogEvent);
    }
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <button
      className={`${
        className || ""
      } w-6 h-6 my-1 bg-mainTintColor text-xs text-white rounded-full flex items-center justify-center ${
        postHogEvent ? "ph-no-capture" : ""
      }`}
      onClick={handleClick}
      {...props}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />
    </button>
  );
};

const TripHeader = ({ trip, hideIntro }) => {
  const { agent } = trip;
  const reference = useMemo(
    () => doc(collection(firestore, "profiles"), agent.id),
    [agent.id],
  );
  const [agentProfile] = useDocumentData(reference);

  const showDateBar = trip.startDate && trip.endDate;
  const startTime = moment.utc(trip.startDate);
  const endTime = moment.utc(trip.endDate);

  const agentName = agentProfile
    ? agentProfile.displayName
    : agent.cachedDisplayName;
  const agentSubTitle = "Your travel curator";

  return (
    <div className="darkmode bg-oldBackgroundColor text-textColor">
      <div className="relative">
        <Image
          path={trip.image.url || trip.image.path}
          aspect={headerAspectRatio}
        />
        <div
          className="absolute top-0 w-full h-full flex flex-col justify-end items-center"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 1) 100%",
          }}
        >
          {trip.users && (
            <div
              className="relative flex-shrink-0 h-8 mb-3"
              style={{ width: `${1.3 * (trip.users.length - 1) + 2}rem` }}
            >
              <AvatarList>
                {[...trip.users].reverse().map((userId) => (
                  <Avatar key={userId} userId={userId} border />
                ))}
              </AvatarList>
            </div>
          )}
          <div className="text-3xl font-serif font-bold mb-5 text-center mx-4">
            {trip.title}
          </div>
          {trip.subtitle?.length > 0 && (
            <FormattedText
              className="text-center mx-4 mb-5"
              source={trip.subtitle || ""}
            />
          )}
          {showDateBar && (
            <div
              className="flex flex-row items-center"
              style={{ lineHeight: 1.75 }}
            >
              <div
                className="flex flex-col items-center"
                style={{ width: "5.5rem" }}
              >
                <div className="text-xs font-bold text-textQuarternaryColor">
                  {startTime.format("dddd")}
                </div>
                <div className="font-bold">{startTime.format("DD MMM")}</div>
                <div className="text-xs text-textQuarternaryColor">
                  {startTime.format("YYYY")}
                </div>
              </div>
              <div className="font-bold text-textQuarternaryColor">/</div>
              <div
                className="flex flex-col items-center"
                style={{ width: "5.5rem" }}
              >
                <div className="text-xs font-bold text-textQuarternaryColor">
                  {endTime.format("dddd")}
                </div>
                <div className="font-bold">{endTime.format("DD MMM")}</div>
                <div className="text-xs text-textQuarternaryColor">
                  {endTime.format("YYYY")}
                </div>
              </div>
              <div
                className="flex flex-col items-center border-l border-separatorColor"
                style={{ width: "5.5rem" }}
              >
                <div className="text-xs font-bold text-textQuarternaryColor">
                  Duration
                </div>
                <div className="font-bold">
                  {endTime.diff(startTime, "days") + 1}
                </div>
                <div className="text-xs text-textQuarternaryColor">Days</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="px-4">
        {hideIntro ? (
          <div className="pt-12" />
        ) : (
          <>
            <div className="mt-6 pt-12 border-t border-separatorColor">
              <FormattedText source={trip.formattedIntro || ""} />
            </div>
            <div className="mt-8 mb-12 flex items-center">
              <Avatar userId={agent.id} border />
              <div className="flex flex-col justify-center leading-tight ml-2">
                <div className="text-md font-bold">{agentName}</div>
                <div className="text-xs text-textSecondaryColor">
                  {agentSubTitle}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const TripEditor = ({ value, setValue }) => {
  return (
    <>
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Trip destination"
        value={value.title}
        onChange={(e) => {
          setValue({ ...value, title: e.target.value });
        }}
        autoFocus
      />
      <div className="flex mb-2">
        <ImageUpload
          className="flex-grow mr-2"
          image={value.image}
          onChange={(image) => setValue({ ...value, image })}
        />
        {value.startDate && value.endDate && (
          <DateRangePicker
            value={[value.startDate, value.endDate]}
            onChange={([startDate, endDate]) =>
              setValue({ ...value, startDate, endDate })
            }
          />
        )}
      </div>

      <label>Intro Text</label>
      <TextareaAutosize
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 m-h-200px"
        placeholder="If you want an intro text, please fill it in"
        value={value.formattedIntro}
        onChange={(e) => {
          setValue({ ...value, formattedIntro: e.target.value });
        }}
      />

      <label>Share Text</label>
      <TextareaAutosize
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none my-1 m-h-50px"
        placeholder="If you want a share text, please fill it in"
        value={value.subtitle}
        onChange={(e) => {
          setValue({ ...value, subtitle: e.target.value });
        }}
      />
      <div
        className="automated hover:cursor-pointer"
        onClick={() => {
          setValue({
            ...value,
            automatedDayDisabled: !value.automatedDayDisabled,
          });
        }}
      >
        <input
          style={{ verticalAlign: "middle", marginRight: "5px" }}
          type="checkbox"
          checked={!!value.automatedDayDisabled}
          readOnly={true}
        />
        <span className="text-xs">
          Disable automated Day and Title of Section Headers
        </span>
      </div>
    </>
  );
};

const EditableTripHeader = ({
  trip,
  hideIntro = false,
  onChange,
  readonly,
}) => {
  const Viewer = ({ value }) => (
    <TripHeader trip={value} hideIntro={hideIntro} />
  );
  return (
    <Editable
      ViewerComponent={Viewer}
      editorComponents={[TripEditor]}
      value={trip}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

const AgentCalloutCardEditor = ({ value, setValue }) => {
  return (
    <TextareaAutosize
      className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
      placeholder="Text"
      value={value.info.formattedText}
      onChange={(e) =>
        setValue(
          modify(value, (draft) => {
            draft.info.formattedText = e.target.value;
          }),
        )
      }
      autoFocus
    />
  );
};

const CalloutCardEditor = ({ value, setValue }) => {
  return (
    <>
      <div className="flex">
        <input
          type="text"
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 flex-grow"
          placeholder="Callout title (optional)"
          value={value.info.title || ""}
          onChange={(e) =>
            setValue(
              modify(value, (draft) => {
                draft.info.title = e.target.value || undefined;
              }),
            )
          }
        />
        <input
          type="checkbox"
          className="m-2 mt-0"
          checked={value.info.collapsible}
          onChange={(e) =>
            setValue({
              ...value,
              info: { ...value.info, collapsible: e.target.checked },
            })
          }
        />
        <label className="mb-2">Collapsible</label>
      </div>
      <TextareaAutosize
        className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Text"
        value={value.info.formattedText}
        onChange={(e) =>
          setValue(
            modify(value, (draft) => {
              draft.info.formattedText = e.target.value;
            }),
          )
        }
        autoFocus
      />
      <EmojiPicker
        className="wysiwig-picker"
        value={value.info.emoji}
        onChange={(emoji) =>
          setValue(
            modify(value, (draft) => {
              draft.info.emoji = emoji;
            }),
          )
        }
      />
    </>
  );
};

const PaymentCardEditor = ({ value, setValue }) => {
  const [amount, setAmount] = useState(value.info.amount.toString());

  return (
    <>
      <div className="flex items-stretch mb-2">
        <SegmentedControl
          options={currencyOptions}
          value={value.info.currency}
          onChange={(currency) =>
            setValue(
              modify(value, (draft) => {
                draft.info.currency = currency;
              }),
            )
          }
          className="w-16"
        />
        <input
          type="text"
          className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
          placeholder="Amount"
          value={amount}
          onChange={(e) => {
            const stripped = e.target.value.replace(/[^0-9.]/g, "");
            setAmount(stripped);

            setValue(
              modify(value, (draft) => {
                draft.info.amount = Number.parseFloat(stripped) || 0;
              }),
            );
          }}
          autoFocus
        />
      </div>
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Title"
        value={value.info.title}
        onChange={(e) =>
          setValue(
            modify(value, (draft) => {
              draft.info.title = e.target.value;
            }),
          )
        }
      />
      <TextareaAutosize
        className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
        placeholder="Text"
        value={value.info.formattedMessage}
        onChange={(e) =>
          setValue(
            modify(value, (draft) => {
              draft.info.formattedMessage = e.target.value;
            }),
          )
        }
      />
    </>
  );
};

const InAppPropertiesViewer = ({ value, paymentId, tripRequestId, tripId }) => {
  const paymentReference = useMemo(
    () =>
      doc(
        collection(
          doc(
            collection(
              doc(collection(firestore, "draftTripRequests"), tripRequestId),
              "trips",
            ),
            tripId,
          ),
          "payments",
        ),
        paymentId,
      ),
    [tripRequestId, tripId, paymentId],
  );
  const [paymentData, paymentDataLoading] = useDocumentData(paymentReference);
  if (paymentDataLoading || !paymentData) return null;
  const {
    type,
    description,
    stripeInvoiceDescription,
    stripePaymentDescription,
    currency,
    paymentAmount,
    paymentTitle,
    confirmationType,
    totalTripAmount,
    nonMemberOptions,
  } = paymentData;

  const trialOption = nonMemberOptions?.find((nmo) => nmo.type === "trial");

  return (
    <div>
      <div className="text-sm">
        Saved invoice properties, if you want to change any of these you need to
        delete the block and start over again
      </div>
      <div>
        <label>Payment Type:</label>
        <select
          className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2 text-xs font-mono"
          value={type}
          disabled={true}
        >
          <option value="confirmation">Confirmational Payment</option>
          <option value="additional">Additional Payment</option>
        </select>
      </div>
      {type === "confirmation" && (
        <div className="flex">
          <div className="pr-10 flex py-1">
            <input
              type="radio"
              style={{ verticalAlign: "middle", height: "100%" }}
              name="confirmation-type-deposit"
              value={"deposit"}
              checked={confirmationType === "deposit"}
            />
            <div className="pl-2">Deposit</div>
          </div>
          <div className="flex py-1">
            <input
              type="radio"
              style={{ verticalAlign: "middle", height: "100%" }}
              name="confirmation-type-full"
              value={"full"}
              checked={confirmationType === "full"}
            />
            <div className="pl-2">Full Payment</div>
          </div>
        </div>
      )}
      <div>
        <div>
          <label>Payment Card Title</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Title"
            value={value.info.title}
            disabled={true}
          />
        </div>
        <div>
          <label>Payment Card Body</label>
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Text"
            value={value.info.formattedMessage}
            disabled={true}
          />
        </div>
        <div>
          <label>Payment Card Amount Label</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Label Text"
            value={value.info.amountLabel}
            disabled={true}
          />
        </div>
        {type === "additional" && (
          <div>
            <label>Payment Title For Payment Page</label>
            <input
              type="text"
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
              placeholder="Title"
              value={paymentTitle}
              disabled={true}
            />
          </div>
        )}
        {type === "confirmation" && (
          <div>
            <label>Total Trip Amount (excluding fee)</label>
            <div className="flex items-stretch mb-2">
              <SegmentedControl
                options={currencyOptions}
                value={currency}
                className="w-16"
              />
              <input
                type="text"
                className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                placeholder="Amount"
                value={currencyLib(totalTripAmount, { fromCents: true })}
                disabled={true}
              />
            </div>
          </div>
        )}
        {type === "confirmation" && confirmationType === "deposit" && (
          <div>
            <label>Trip Deposit Amount</label>
            <br />
            <div className="flex items-stretch mb-2">
              <SegmentedControl
                options={currencyOptions}
                value={currency}
                className="w-16"
              />
              <input
                type="text"
                className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                placeholder="Amount"
                value={currencyLib(paymentAmount, { fromCents: true })}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div>
          <label>Payment Page Description</label>
          <br />
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none w-full"
            placeholder="Text"
            value={description}
            disabled={true}
          />
        </div>
        <div>
          <label>Stripe Invoice Description</label>
          <br />
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none w-full"
            placeholder="Text"
            value={stripeInvoiceDescription}
            disabled={true}
          />
        </div>
        <div>
          <label>Stripe Payment Description (One liner)</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Description"
            value={stripePaymentDescription}
            disabled={true}
          />
        </div>
        {type === "confirmation" &&
          nonMemberOptions &&
          nonMemberOptions.length > 0 && (
            <div>
              {trialOption && (
                <>
                  <div>
                    <label>Scenset Fee Title</label>
                    <input
                      type="text"
                      className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                      placeholder="Description"
                      value={trialOption.title}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label>Scenset Fee Cost</label>
                    <br />
                    <div className="flex items-stretch mb-2">
                      <SegmentedControl
                        options={currencyOptions}
                        value={currency}
                        className="w-16"
                      />
                      <input
                        type="text"
                        className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                        placeholder="Scenset Fee Cost Amount"
                        value={currencyLib(trialOption.paymentAmount, {
                          fromCents: true,
                        })}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Scenset Fee Description For Payment Screen</label>
                    <input
                      type="text"
                      className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                      placeholder="Description"
                      value={trialOption.description}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label>Scenset Fee Description For Stripe Invoice</label>
                    <input
                      type="text"
                      className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                      placeholder="Description"
                      value={trialOption.stripePaymentDescription}
                      disabled={true}
                    />
                  </div>
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

const InAppPropertiesEditor = ({ value, setValue, tripRequestId, users }) => {
  const saveData = async () => {
    const getPaymentAmount = () => {
      if (value.metadata.paymentType === "additional")
        return value.metadata.additionalAmount;
      if (value.metadata.confirmationType === "deposit")
        return value.metadata.tripDepositAmount;
      return value.metadata.totalTripAmount;
    };
    if (!users.length) {
      // eslint-disable-next-line no-alert
      alert(`A payment block can't be created unless there's a client attached to the trip.
        Please add a client and then try again.`);
      return;
    }
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        "Are you sure you want to finalize the in-app payment properties as is?\n " +
          "Please note you need to delete the block and start all over again if you " +
          "want to change anything (Nour's working on fixing this :D).",
      )
    ) {
      return;
    }
    const tripFeeOption = {
      type: "trial",
      title: value.metadata.trialTitle,
      paymentAmount: currencyLib(value.metadata.trialAmount).intValue,
      description: value.metadata.trialDescription,
      stripePaymentDescription: value.metadata.trialStripeDescription,
    };
    const nonMemberOptions = [];
    if (value.metadata.seeTrialOption) {
      nonMemberOptions.push(tripFeeOption);
    }
    const paymentParams = {
      type: value.metadata.paymentType,
      description: value.metadata.description,
      stripeInvoiceDescription: value.metadata.stripeInvoiceDescription,
      stripePaymentDescription: value.metadata.stripePaymentDescription,
      currency: value.info.currency,
      paymentAmount: currencyLib(getPaymentAmount()).intValue,
      dueDateISOString: value.info.dueDate.toDate().toISOString(),
      paymentTitle:
        value.metadata.paymentType === "additional"
          ? value.info.title
          : "Book this trip",
      ...(value.metadata.paymentType === "confirmation" && {
        confirmationType: value.metadata.confirmationType,
        totalTripAmount: currencyLib(value.metadata.totalTripAmount).intValue,
        nonMemberOptions,
      }),
    };

    const processPaymentCommand = httpsCallable(
      functions,
      "processPaymentCommand",
    );
    const paymentBlockData = await processPaymentCommand({
      command: "CreatePaymentBlockEntities",
      params: {
        userId: users[0],
        tripRequestId,
        selectedNonMemberOption: tripFeeOption.type,
        paymentParams,
      },
    });
    const buttonText =
      value.metadata.paymentType === "additional"
        ? "Go to payment"
        : "Book this trip";
    const { paymentId, hostedInvoiceUrl } = paymentBlockData.data;
    setValue(
      modify(value, (draft) => {
        draft.info.paymentId = paymentId;
        draft.info.buttonText = buttonText;
        // pass invoice URL into payment block
        draft.info.hostedInvoiceUrl = hostedInvoiceUrl;
      }),
    );
  };

  return (
    <div>
      <div className="text-sm">
        Please note, once saved you have to delete the block and start again to
        change anything
      </div>
      <div>
        <div>
          <label>Payment Type</label>
          <select
            className="block w-full bg-chatInputBackgroundColor border border-searchBorderColor rounded leading-tight p-2 text-xs font-mono"
            value={value.metadata.paymentType}
            onChange={(e) => {
              const currentAmount =
                e.target.value === "additional"
                  ? value.metadata.additionalAmount
                  : value.metadata.totalTripAmount;
              setValue(
                modify(value, (draft) => {
                  draft.metadata.paymentType = e.target.value;
                  draft.info.amount = currencyLib(currentAmount).intValue;
                }),
              );
            }}
          >
            <option value="confirmation">Confirmational Payment</option>
            <option value="additional">Additional Payment</option>
          </select>
        </div>
        {value.metadata.paymentType === "confirmation" && (
          <div className="flex">
            <div className="pr-10 flex py-1">
              <input
                type="radio"
                style={{ verticalAlign: "middle", height: "100%" }}
                name="confirmation-type-deposit"
                value={"deposit"}
                checked={value.metadata.confirmationType === "deposit"}
                onChange={() => {
                  if (value.metadata.confirmationType !== "deposit")
                    setValue(
                      modify(value, (draft) => {
                        draft.metadata.confirmationType = "deposit";
                      }),
                    );
                }}
              />
              <div className="pl-2">Deposit</div>
            </div>
            <div className="flex py-1">
              <input
                type="radio"
                style={{ verticalAlign: "middle", height: "100%" }}
                name="confirmation-type-full"
                value={"full"}
                checked={value.metadata.confirmationType === "full"}
                onChange={() => {
                  if (value.metadata.confirmationType !== "full")
                    setValue(
                      modify(value, (draft) => {
                        draft.metadata.confirmationType = "full";
                      }),
                    );
                }}
              />
              <div className="pl-2">Full Payment</div>
            </div>
          </div>
        )}
        <div>
          <label>Payment Card Title</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Title"
            value={value.info.title}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.info.title = e.target.value;
                }),
              )
            }
          />
        </div>
        <div>
          <label>Payment Card Body</label>
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Text"
            value={value.info.formattedMessage}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.info.formattedMessage = e.target.value;
                }),
              )
            }
          />
        </div>
        <div>
          <label>Payment Card Amount Label</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Label Text"
            value={value.info.amountLabel}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.info.amountLabel = e.target.value;
                }),
              )
            }
          />
        </div>
        {value.metadata.paymentType === "additional" && (
          <div>
            <label>Additional Amount</label>
            <div className="flex items-stretch mb-2">
              <SegmentedControl
                options={currencyOptions}
                value={value.info.currency}
                onChange={(currency) =>
                  setValue(
                    modify(value, (draft) => {
                      draft.info.currency = currency;
                    }),
                  )
                }
                className="w-16"
              />
              <input
                type="text"
                className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                placeholder="Amount"
                value={value.metadata.additionalAmount}
                onChange={(e) => {
                  const stripped = e.target.value.replace(/[^0-9.]/g, "");
                  setValue(
                    modify(value, (draft) => {
                      draft.metadata.additionalAmount = stripped;
                      draft.info.amount = currencyLib(stripped).intValue;
                    }),
                  );
                }}
                autoFocus
              />
            </div>
          </div>
        )}
        {value.metadata.paymentType === "confirmation" && (
          <div>
            <label>Total Trip Amount (excluding fee)</label>
            <div className="flex items-stretch mb-2">
              <SegmentedControl
                options={currencyOptions}
                value={value.info.currency}
                onChange={(currency) =>
                  setValue(
                    modify(value, (draft) => {
                      draft.info.currency = currency;
                    }),
                  )
                }
                className="w-16"
              />
              <input
                type="text"
                className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                placeholder="Amount"
                value={value.metadata.totalTripAmount}
                onChange={(e) => {
                  const stripped = e.target.value.replace(/[^0-9.]/g, "");
                  setValue(
                    modify(value, (draft) => {
                      draft.metadata.totalTripAmount = stripped;
                      draft.info.amount =
                        currencyLib(stripped).intValue +
                        currencyLib(draft.metadata.trialAmount).intValue;
                    }),
                  );
                }}
                autoFocus
              />
            </div>
          </div>
        )}
        {value.metadata.paymentType === "confirmation" &&
          value.metadata.confirmationType === "deposit" && (
            <>
              <div>
                <label>Trip Deposit Amount</label>
                <br />
                <div className="flex items-stretch mb-2">
                  <SegmentedControl
                    options={currencyOptions}
                    value={value.info.currency}
                    onChange={(currency) =>
                      setValue(
                        modify(value, (draft) => {
                          draft.info.currency = currency;
                        }),
                      )
                    }
                    className="w-16"
                  />
                  <input
                    type="text"
                    className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                    placeholder="Amount"
                    value={value.metadata.tripDepositAmount}
                    onChange={(e) => {
                      const stripped = e.target.value.replace(/[^0-9.]/g, "");
                      setValue(
                        modify(value, (draft) => {
                          draft.metadata.tripDepositAmount = stripped;
                        }),
                      );
                    }}
                    autoFocus
                  />
                </div>
              </div>
            </>
          )}
        <div>
          <label>Payment Due Date:</label>
          <br />
          <DateTimePicker
            value={{
              date: value.info.dueDate,
              timeZone: "UTC",
            }}
            onChange={(dueDateWithTimezone) => {
              setValue(
                modify(value, (draft) => {
                  draft.info.dueDate = dueDateWithTimezone.date;
                }),
              );
            }}
          />
        </div>
        <div>
          <label>Payment Page Description</label>
          <br />
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none w-full"
            placeholder="Text"
            value={value.metadata.description}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.metadata.description = e.target.value;
                }),
              )
            }
          />
        </div>
        <div>
          <label>Stripe Invoice Description</label>
          <br />
          <TextareaAutosize
            className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none w-full"
            placeholder="Text"
            value={value.metadata.stripeInvoiceDescription}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.metadata.stripeInvoiceDescription = e.target.value;
                }),
              )
            }
          />
        </div>
        <div>
          <label>Stripe Payment Description (One liner)</label>
          <input
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
            placeholder="Description"
            value={value.metadata.stripePaymentDescription}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  draft.metadata.stripePaymentDescription = e.target.value;
                }),
              )
            }
          />
        </div>
        {value.metadata.paymentType === "confirmation" && (
          <div>
            {value.metadata.seeTrialOption && (
              <>
                <div>
                  <label>Scenset Fee Title</label>
                  <input
                    type="text"
                    className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                    placeholder="Description"
                    value={value.metadata.trialTitle}
                    onChange={(e) =>
                      setValue(
                        modify(value, (draft) => {
                          draft.metadata.trialTitle = e.target.value;
                        }),
                      )
                    }
                  />
                </div>
                <div>
                  <label>Scenset Fee Cost</label>
                  <br />
                  <div className="flex items-stretch mb-2">
                    <SegmentedControl
                      options={currencyOptions}
                      value={value.info.currency}
                      onChange={(currency) =>
                        setValue(
                          modify(value, (draft) => {
                            draft.info.currency = currency;
                          }),
                        )
                      }
                      className="w-16"
                    />
                    <input
                      type="text"
                      className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none ml-2"
                      placeholder="Scenset Fee Cost Amount"
                      value={value.metadata.trialAmount}
                      onChange={(e) => {
                        const stripped = e.target.value.replace(/[^0-9.]/g, "");
                        setValue(
                          modify(value, (draft) => {
                            draft.metadata.trialAmount = stripped;
                            draft.info.amount =
                              currencyLib(stripped).intValue +
                              currencyLib(draft.metadata.totalTripAmount)
                                .intValue;
                          }),
                        );
                      }}
                      autoFocus
                    />
                  </div>
                </div>
                <div>
                  <label>Scenset Fee Description For Payment Screen</label>
                  <input
                    type="text"
                    className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                    placeholder="Description"
                    value={value.metadata.trialDescription}
                    onChange={(e) =>
                      setValue(
                        modify(value, (draft) => {
                          draft.metadata.trialDescription = e.target.value;
                        }),
                      )
                    }
                  />
                </div>
                <div>
                  <label>Scenset Fee Description For Stripe Invoice</label>
                  <input
                    type="text"
                    className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 w-full"
                    placeholder="Description"
                    value={value.metadata.trialStripeDescription}
                    onChange={(e) =>
                      setValue(
                        modify(value, (draft) => {
                          draft.metadata.trialStripeDescription =
                            e.target.value;
                        }),
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
        <ActionButton className="mt-3" onAction={saveData}>
          Finalize In-App Payment Block
        </ActionButton>
      </div>
    </div>
  );
};

const InAppPaymentCardEditor = ({ value, setValue }) => {
  const tripRequestId = useContext(TripRequestIdContext);

  const tripRequestReference = useMemo(
    () => doc(collection(firestore, "draftTripRequests"), tripRequestId),
    [tripRequestId],
  );
  const [tripRequestData, tripRequestLoading] =
    useDocumentData(tripRequestReference);
  if (tripRequestLoading) return null;

  return (
    <>
      {value.info.paymentId === "%CHANGE_ME%" ? (
        <InAppPropertiesEditor
          value={value}
          setValue={setValue}
          tripRequestId={tripRequestId}
          users={tripRequestData.users}
        />
      ) : (
        <InAppPropertiesViewer
          value={value}
          paymentId={value.info.paymentId}
          tripRequestId={tripRequestId}
          tripId={tripRequestData.chosenTripId}
        />
      )}
    </>
  );
};

const TransportationLegEditor = ({ className, value, onChange }) => {
  return (
    <div className={`${className || ""} flex flex-col`}>
      <AirportLookup
        className="mb-2"
        onSelect={({ code, city, timeZone }) => {
          onChange({
            ...value,
            time: { ...value.time, timeZone },
            title: city,
            subTitle: code,
          });
        }}
      />
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Title (e.g. city)"
        value={value.title}
        onChange={(e) => {
          onChange({ ...value, title: e.target.value });
        }}
      />
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Sub title (e.g. airport code)"
        value={value.subTitle}
        onChange={(e) => {
          onChange({ ...value, subTitle: e.target.value });
        }}
      />
      <DateTimePicker
        value={value.time}
        onChange={(time) => {
          onChange({ ...value, time });
        }}
      />
    </div>
  );
};

const TransportationCardEditor = ({ value, setValue }) => {
  const metadataItems = [...(value.info.metadata || []).filter((i) => i), ""];

  return (
    <>
      <SegmentedControl
        options={transportationOptions}
        value={value.info.icon}
        onChange={(icon) =>
          setValue(
            modify(value, (draft) => {
              draft.info.icon = icon;
            }),
          )
        }
        className="mb-2"
      />
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Title (e.g. flight number)"
        value={value.info.title}
        onChange={(e) =>
          setValue(
            modify(value, (draft) => {
              draft.info.title = e.target.value;
            }),
          )
        }
        autoFocus
      />
      <div className="flex justify-between">
        <TransportationLegEditor
          value={value.info.departure}
          onChange={(leg) =>
            setValue(
              modify(value, (draft) => {
                draft.info.departure = leg;
              }),
            )
          }
        />
        <TransportationLegEditor
          className="ml-2"
          isPlane={value.info.icon === "plane"}
          value={value.info.arrival}
          onChange={(leg) =>
            setValue(
              modify(value, (draft) => {
                draft.info.arrival = leg;
              }),
            )
          }
        />
      </div>
      {metadataItems.map((item, index) => {
        return (
          <input
            key={index}
            type="text"
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mt-2"
            placeholder="Add metadata"
            value={item}
            onChange={(e) =>
              setValue(
                modify(value, (draft) => {
                  metadataItems[index] = e.target.value;
                  draft.info.metadata = metadataItems.filter((i) => i);
                }),
              )
            }
          />
        );
      })}
    </>
  );
};

const CardBlockEditor = ({ value, setValue }) => {
  const { card } = value.info;
  const updateCard = (newCard) =>
    setValue(
      modify(value, (draft) => {
        draft.info.card = newCard;
      }),
    );

  switch (card.type) {
    case "AgentCallout":
      return <AgentCalloutCardEditor value={card} setValue={updateCard} />;
    case "Callout":
      return <CalloutCardEditor value={card} setValue={updateCard} />;
    case "Payment":
      return <PaymentCardEditor value={card} setValue={updateCard} />;
    case "Transportation":
      return <TransportationCardEditor value={card} setValue={updateCard} />;
    default:
      return null;
  }
};

const isSectionHeaderAutomated = (automated, title, startDate, endDate) => {
  if (automated !== undefined) {
    return automated;
  }
  return (
    startsWithDay.test(title) &&
    moment(startDate).isSameOrBefore(moment(endDate))
  );
};

const getSectionHeaderAutomatedTitle = (title) => {
  const matches = startsWithDay.exec(title);
  const retVal = matches?.[1];
  if (retVal === undefined) {
    return title;
  }
  return retVal;
};

const SectionHeaderGroupEditor = ({
  hiddenFromAdmin,
  blocks,
  setBlocks,
  automated,
  setAutomated,
  automatedDayDisabled,
}) => {
  const dateHeaderBlockIndex = 1;
  const headerBlockIndex = 2;

  const isAutomated =
    !automatedDayDisabled &&
    isSectionHeaderAutomated(
      automated,
      blocks[headerBlockIndex].info.title,
      blocks[dateHeaderBlockIndex].info.startDate,
      blocks[dateHeaderBlockIndex].info.endDate,
    );
  const title = isAutomated
    ? getSectionHeaderAutomatedTitle(blocks[headerBlockIndex].info.title)
    : blocks[headerBlockIndex].info.title;
  const titleStart =
    blocks[headerBlockIndex].info.title !== title && title !== ""
      ? blocks[headerBlockIndex].info.title.slice(0, -title.length)
      : "Day #: ";
  return (
    <>
      <div>
        {isAutomated && <span className="text-xs">{titleStart}</span>}
        <input
          type="text"
          className={`bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2 ${
            !isAutomated ? "w-full" : "w-3/4"
          }`}
          placeholder="Title"
          value={title}
          onChange={(e) =>
            setBlocks(
              modify(blocks, (draft) => {
                draft[headerBlockIndex].info.title =
                  (isAutomated ? "Day #: " : "") + e.target.value;
              }),
            )
          }
          autoFocus
        />
      </div>
      <div className="self-start">
        <DateRangePicker
          value={[
            blocks[dateHeaderBlockIndex].info.startDate,
            blocks[dateHeaderBlockIndex].info.endDate,
          ]}
          onChange={([startDate, endDate]) =>
            setBlocks(
              modify(blocks, (draft) => {
                draft[dateHeaderBlockIndex].info.startDate = startDate;
                draft[dateHeaderBlockIndex].info.endDate = endDate;
              }),
            )
          }
        />
        {isAutomated && <span className="text-xs">Date is automated</span>}
        {automatedDayDisabled && (
          <span className="text-xs">
            Date automation disabled. You can re-enable it in Trip Header.
          </span>
        )}
      </div>
      {!hiddenFromAdmin && !automatedDayDisabled && (
        <div
          className="automated hover:cursor-pointer"
          onClick={() => setAutomated(!isAutomated)}
        >
          <input
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            type="checkbox"
            checked={isAutomated}
            readOnly={true}
          />
          <span className="text-xs">Automate Trip Day & Title</span>
        </div>
      )}
    </>
  );
};

const GroupBlockEditor = ({ value, setValue, automatedDayDisabled }) => {
  const updateBlocks = (blocks) =>
    setValue(
      modify(value, (draft) => {
        draft.info.blocks = blocks;
      }),
    );
  const updateAutomated = (automated) =>
    setValue(
      modify(value, (draft) => {
        if (!draft.metadata) draft.metadata = {};
        draft.metadata.automated = automated;
      }),
    );

  switch (value.info.kind) {
    case "SectionHeader":
      return (
        <>
          <SectionHeaderGroupEditor
            hiddenFromAdmin={isBlockHiddenFromAdmin(value)}
            blocks={value.info.blocks}
            setBlocks={updateBlocks}
            automated={value?.metadata?.automated}
            setAutomated={updateAutomated}
            automatedDayDisabled={automatedDayDisabled}
          />
        </>
      );
    default:
      return null;
  }
};

const DividerBlockEditor = ({ value, setValue }) => {
  return (
    <SegmentedControl
      options={dividerStyleOptions}
      value={value.info?.style || "full"}
      onChange={(style) =>
        setValue(
          modify(value, (draft) => {
            if (!draft.info) draft.info = {};
            draft.info.style = style;
          }),
        )
      }
    />
  );
};

const GalleryBlockEditor = ({ value, setValue }) => {
  const aspect = useContext(GalleryAspectContext);

  const swapImages = (index1, index2) =>
    setValue(
      modify(value, (draft) => {
        [draft.info.images[index1], draft.info.images[index2]] = [
          draft.info.images[index2],
          draft.info.images[index1],
        ];
      }),
    );

  return (
    <div className="flex-grow overflow-y-auto flex flex-col">
      {value.info.images.map((image, index) => {
        const setImage = (newImage) =>
          setValue(
            modify(value, (draft) => {
              draft.info.images[index] = newImage;
            }),
          );

        const deleteImage = () =>
          setValue(
            modify(value, (draft) => {
              draft.info.images.splice(index, 1);
            }),
          );

        const moveUp = index > 0 ? () => swapImages(index, index - 1) : null;
        const moveDown =
          index < value.info.images.length - 1
            ? () => swapImages(index, index + 1)
            : null;

        return (
          <div key={index} className="flex mb-7">
            <ImageUpload
              className="mt-6 w-24 self-start"
              image={image}
              onChange={setImage}
              aspect={aspect}
            />
            <div className="mt-6 flex flex-col justify-center -mx-2 z-10">
              {moveUp && (
                <EditorIcon
                  icon={upIcon}
                  onClick={moveUp}
                  postHogEvent="clicked_gallery_block_editor_move_up"
                />
              )}
              <EditorIcon
                icon={deleteIcon}
                onClick={deleteImage}
                postHogEvent="clicked_gallery_block_editor_delete"
              />
              {moveDown && (
                <EditorIcon
                  icon={downIcon}
                  onClick={moveDown}
                  postHogEvent="clicked_gallery_block_editor_move_down"
                />
              )}
            </div>
            <div className="flex-grow">
              <label>Description</label>
              <TextareaAutosize
                className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
                placeholder="Description"
                value={image.caption}
                onChange={(e) =>
                  setImage({
                    ...image,
                    caption: e.target.value.replace(/\n/g, ""),
                  })
                }
              />
              <label>Image Credits</label>
              <input
                type="text"
                className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
                placeholder="Image Credits"
                value={image.attribution}
                onChange={(e) =>
                  setImage({
                    ...image,
                    attribution: e.target.value.replace(/\n/g, ""),
                  })
                }
              />
            </div>
          </div>
        );
      })}
      <ImageUpload
        className="w-24 self-start"
        image={{ url: "" }}
        multiple
        onChange={(image) =>
          setValue(
            modify(value, (draft) => {
              draft.info.images.push({
                ...image,
                caption: "",
                attribution: "",
              });
            }),
          )
        }
        aspect={aspect}
      />
    </div>
  );
};

const HeaderBlockEditor = ({ value, setValue }) => {
  return (
    <>
      <SegmentedControl
        options={headerStyleOptions}
        value={value.info.style || "main"}
        onChange={(style) =>
          setValue(
            modify(value, (draft) => {
              draft.info = { ...draft.info, ...headerStyles[style] };
            }),
          )
        }
        className="mb-2"
      />
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
        placeholder="Header"
        value={value.info.title}
        onChange={(e) =>
          setValue(
            modify(value, (draft) => {
              draft.info.title = e.target.value;
            }),
          )
        }
        autoFocus
      />
    </>
  );
};

const PhotoBlockEditor = ({ value, setValue }) => {
  const setImage = (newImage) =>
    setValue(
      modify(value, (draft) => {
        draft.info.image = newImage;
      }),
    );

  return (
    <div className="flex">
      <ImageUpload
        className="w-24 self-start mr-2"
        image={value.info.image}
        onChange={setImage}
      />
      <div className="flex-grow">
        <label>Description</label>
        <TextareaAutosize
          className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
          placeholder="Description"
          value={value.info.image.caption}
          onChange={(e) =>
            setImage({
              ...value.info.image,
              caption: e.target.value.replace(/\n/g, ""),
            })
          }
        />
        <label>Image Credits</label>
        <input
          type="text"
          className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
          placeholder="Image Credits"
          value={value.info.image.attribution}
          onChange={(e) =>
            setImage({
              ...value.info.image,
              attribution: e.target.value.replace(/\n/g, ""),
            })
          }
        />
      </div>
    </div>
  );
};

const validateLatitude = (latitude) => {
  const floatValue =
    typeof latitude === "string" ? Number.parseFloat(latitude) : latitude;
  if (!floatValue) return undefined;
  if (floatValue < -90 || floatValue > 90) return undefined;
  return floatValue;
};

const validateLongitude = (longitude) => {
  const floatValue =
    typeof longitude === "string" ? Number.parseFloat(longitude) : longitude;
  if (!floatValue) return undefined;
  if (floatValue < -180 || floatValue > 180) return undefined;
  return floatValue;
};

const LocationEditor = ({ className, value, onChange }) => {
  const [latitude, setLatitude] = useState(
    value.location.latitude ? value.location.latitude.toString() : "",
  );
  const [longitude, setLongitude] = useState(
    value.location.longitude ? value.location.longitude.toString() : "",
  );

  return (
    <div className={`${className || ""} flex flex-col`}>
      <SegmentedControl
        options={locationOptions}
        value={value.icon || ""}
        onChange={(icon) => onChange({ ...value, icon: icon || undefined })}
        className="mb-2"
      />
      <div className="flex mb-2">
        <PlaceLookup
          className="flex-1"
          onSelect={({ name, location, icon }) => {
            setLatitude(location.latitude.toString());
            setLongitude(location.longitude.toString());
            onChange({ ...value, location, icon, title: name, subTitle: "" });
          }}
        />
        <AirportLookup
          className="flex-1 ml-2"
          onSelect={({ code, city, location }) => {
            setLatitude(location.latitude.toString());
            setLongitude(location.longitude.toString());
            onChange({
              ...value,
              location,
              icon: "airport",
              title: city,
              subTitle: `(${code})`,
            });
          }}
        />
      </div>
      <div className="flex mb-2">
        <input
          type="text"
          className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
          placeholder="Title"
          value={value.title || ""}
          onChange={(e) => {
            onChange({ ...value, title: e.target.value || undefined });
          }}
        />
        <input
          type="text"
          className={`${
            validateLatitude(latitude)
              ? "border-searchBorderColor"
              : "border-mainTintColor text-mainTintColor"
          } flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border rounded px-2 py-1 appearance-none ml-2`}
          placeholder="Latitude"
          value={latitude}
          onChange={(e) => {
            const stripped = e.target.value.replace(/[^0-9.]/g, "");
            setLatitude(stripped);
            const validated = validateLatitude(stripped);
            if (validated) {
              onChange(
                modify(value, (draft) => {
                  draft.location.latitude = validated;
                }),
              );
            }
          }}
        />
      </div>
      <div className="flex">
        <input
          type="text"
          className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
          placeholder="Sub title"
          value={value.subTitle || ""}
          onChange={(e) => {
            onChange({ ...value, subTitle: e.target.value || undefined });
          }}
        />
        <input
          type="text"
          className={`${
            validateLongitude(longitude)
              ? "border-searchBorderColor"
              : "border-mainTintColor text-mainTintColor"
          } flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border rounded px-2 py-1 appearance-none ml-2`}
          placeholder="Longitude"
          value={longitude}
          onChange={(e) => {
            const stripped = e.target.value.replace(/[^0-9.]/g, "");
            setLongitude(stripped);
            const validated = validateLongitude(stripped);
            if (validated) {
              onChange(
                modify(value, (draft) => {
                  draft.location.longitude = validated;
                }),
              );
            }
          }}
        />
      </div>
    </div>
  );
};

const LegEditor = ({ className, value, onChange }) => (
  <div className={`${className || ""} flex flex-col`}>
    <SegmentedControl
      options={routeTypeOptions}
      value={value.routeType}
      onChange={(routeType) =>
        onChange(
          modify(value, (draft) => {
            draft.routeType = routeType;
            if (routeType === "route" && draft.transportationType === "plane")
              draft.transportationType = "car";
          }),
        )
      }
      className="mb-2"
    />
    <SegmentedControl
      options={transportationOptions}
      value={value.transportationType}
      onChange={(transportationType) =>
        onChange(
          modify(value, (draft) => {
            draft.transportationType = transportationType;
            if (
              ["car", "bus", "bicycle", "walking"].includes(transportationType)
            )
              draft.routeType = "route";
            if (transportationType === "plane") {
              draft.routeType = "geodesic";
              draft.start.icon = "airport";
              draft.end.icon = "airport";
            }
            if (transportationType === "train") {
              draft.routeType = "straight";
              draft.start.icon = "station";
              draft.end.icon = "station";
            }
          }),
        )
      }
      className="mb-2"
    />
    <input
      type="text"
      className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
      placeholder="Label"
      value={value.text}
      onChange={(e) => {
        onChange({ ...value, text: e.target.value });
      }}
    />
    <div className="text-xs mb-1">Departure point:</div>
    <LocationEditor
      className="ml-5 mb-2"
      value={value.start}
      onChange={(start) => onChange({ ...value, start })}
    />
    <div className="text-xs mb-1">Arrival point:</div>
    <LocationEditor
      className="ml-5"
      value={value.end}
      onChange={(end) => onChange({ ...value, end })}
    />
  </div>
);

const RouteMapBlockEditor = ({ value, setValue }) => {
  const [editState, setEditState] = useState(null);

  const attemptItemUpdate = ({ type, index, value: item }) => {
    if (type === "points") {
      if (!validateLatitude(item.location.latitude)) return;
      if (!validateLongitude(item.location.longitude)) return;
    } else if (type === "legs") {
      if (!validateLatitude(item.start.location.latitude)) return;
      if (!validateLongitude(item.start.location.longitude)) return;
      if (!validateLatitude(item.end.location.latitude)) return;
      if (!validateLongitude(item.end.location.longitude)) return;
    }

    setValue(
      modify(value, (draft) => {
        draft.info[type].splice(index, 1, item);
      }),
    );
  };

  const onEditItemChange = (item) => {
    if (!editState) return;
    const newEditState = { ...editState, value: item };
    setEditState(newEditState);
    attemptItemUpdate(newEditState);
  };

  const deleteEditItem = () => {
    if (!editState) return;
    if (value.info[editState.type])
      setValue(
        modify(value, (draft) => {
          draft.info[editState.type].splice(editState.index, 1);
        }),
      );
    setEditState(null);
  };

  let mapStyle = "standard";
  if (value.info.mapboxStyle?.url) {
    if (value.info.mapboxStyle.url.match(/satellite-v\d/))
      mapStyle = "satellite";
    else if (value.info.mapboxStyle.url.match(/satellite-streets-v\d/))
      mapStyle = "hybrid";
  }

  const itemList = (
    type,
    itemDescription,
    ItemComponent,
    ItemEditorComponent,
    newItemFactory,
  ) => {
    const items = value.info[type] || [];
    const isAddingNewItem =
      editState && editState.type === type && editState.index === items.length;
    return (
      <div className="mt-2 flex flex-col">
        {items.map((item, index) => {
          const isEditing =
            editState && editState.type === type && editState.index === index;
          return (
            <Fragment key={index}>
              <button
                type="button"
                className="text-sm text-left flex items-center"
                onClick={() => {
                  setEditState(isEditing ? null : { type, index, value: item });
                }}
              >
                <FontAwesomeIcon
                  className="mr-1 text-textSecondaryColor"
                  icon={isEditing ? openItemIcon : closedItemIcon}
                  fixedWidth
                />
                <ItemComponent item={item} />
              </button>
              {isEditing && (
                <>
                  <ItemEditorComponent
                    className="ml-5 mt-1"
                    value={editState.value}
                    onChange={onEditItemChange}
                  />
                  <button
                    type="button"
                    className="ml-5 mt-2 mb-2 text-sm flex hover:bg-separatorColor justify-center items-center rounded px-2 py-1 border border-searchBorderColor"
                    onClick={deleteEditItem}
                  >
                    <FontAwesomeIcon
                      className="mr-1"
                      size="sm"
                      icon={deleteIcon}
                    />
                    Delete this {itemDescription}
                  </button>
                </>
              )}
            </Fragment>
          );
        })}
        <button
          type="button"
          className="text-sm text-left flex items-center"
          onClick={() => {
            if (isAddingNewItem) {
              setEditState(null);
              return;
            }
            const lastItem =
              items.length > 0 ? items[items.length - 1] : undefined;
            const newItem = newItemFactory(lastItem);
            const newEditState = { type, index: items.length, value: newItem };
            setEditState(newEditState);
            attemptItemUpdate(newEditState);
          }}
        >
          <FontAwesomeIcon
            className={`mr-1 ${isAddingNewItem ? "text-textSecondaryColor" : "text-mainTintColor"}`}
            icon={isAddingNewItem ? openItemIcon : addItemIcon}
            fixedWidth
          />
          {isAddingNewItem ? (
            <ItemComponent item={editState.value} />
          ) : (
            `Add new ${itemDescription}`
          )}
        </button>
        {isAddingNewItem && (
          <>
            <ItemEditorComponent
              className="ml-5 mt-1"
              value={editState.value}
              onChange={onEditItemChange}
            />
            <button
              type="button"
              className="ml-5 mt-2 mb-2 text-sm flex hover:bg-separatorColor justify-center items-center rounded px-2 py-1 border border-searchBorderColor"
              onClick={deleteEditItem}
            >
              <FontAwesomeIcon className="mr-1" size="sm" icon={deleteIcon} />
              Delete this {itemDescription}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <SegmentedControl
        options={mapStyleOptions}
        value={mapStyle}
        onChange={(newMapStyle) =>
          setValue(
            modify(value, (draft) => {
              draft.info.mapboxStyle = mapStyles[newMapStyle];
            }),
          )
        }
        className="mb-2"
      />
      <SegmentedControl
        options={mapSizeOptions}
        value={value.info.mapSize}
        onChange={(mapSize) =>
          setValue(
            modify(value, (draft) => {
              draft.info.mapSize = mapSize;
            }),
          )
        }
      />
      {itemList(
        "points",
        "point",
        ({ item }) => item.title,
        LocationEditor,
        (lastPoint) => ({
          title: "New point",
          subTitle: "",
          location: {},
          icon: lastPoint ? lastPoint.icon : undefined,
        }),
      )}
      {itemList(
        "legs",
        "leg",
        ({ item }) => (
          <>
            {`${item.start.title} → ${item.end.title} (`}
            <OriginText text={item.text} />
            {")"}
          </>
        ),
        LegEditor,
        (lastLeg) => ({
          start: lastLeg
            ? lastLeg.end
            : {
                title: "Departure point",
                subTitle: "",
                location: {},
                icon: "airport",
              },
          end: {
            title: "Arrival point",
            subTitle: "",
            location: {},
            icon: lastLeg ? lastLeg.end.icon : "airport",
          },
          text: "{{duration|abbreviated|2:30}} %CHANGE_ME%",
          transportationType: lastLeg ? lastLeg.transportationType : "plane",
          routeType: lastLeg ? lastLeg.routeType : "geodesic",
        }),
      )}
    </>
  );
};

const TextBlockEditor = ({ value, setValue }) => {
  return (
    <TextareaAutosize
      className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none"
      placeholder="Text"
      value={value.info.formattedText}
      onChange={(e) =>
        setValue(
          modify(value, (draft) => {
            draft.info.formattedText = e.target.value;
          }),
        )
      }
      autoFocus
    />
  );
};

const BlockEditor = ({ value, setValue, editorParams }) => {
  switch (value.type) {
    case "Card":
      return <CardBlockEditor value={value} setValue={setValue} />;
    case "Divider":
      return <DividerBlockEditor value={value} setValue={setValue} />;
    case "Gallery":
      return <GalleryBlockEditor value={value} setValue={setValue} />;
    case "Group":
      return (
        <GroupBlockEditor
          value={value}
          setValue={setValue}
          automatedDayDisabled={editorParams.automatedDayDisabled}
        />
      );
    case "Header":
      return <HeaderBlockEditor value={value} setValue={setValue} />;
    case "Photo":
      return <PhotoBlockEditor value={value} setValue={setValue} />;
    case "RouteMap":
      return <RouteMapBlockEditor value={value} setValue={setValue} />;
    case "Text":
      return <TextBlockEditor value={value} setValue={setValue} />;
    case "InAppPayment":
      return <InAppPaymentCardEditor value={value} setValue={setValue} />;
    default:
      return null;
  }
};

const MemoizedBlock = React.memo(
  Block,
  (a, b) => JSON.stringify(a) === JSON.stringify(b),
);
const BlockWithTimestamps = ({ value, ...props }) => {
  const valueWithTimestamps = useMemo(() => convertTimestamps(value), [value]);
  return <MemoizedBlock {...valueWithTimestamps} {...props} />;
};

const EditableBlock = ({ block, ...props }) => {
  const editorComponents = useMemo(
    () => (props.allowDelete ? [BlockConverter, BlockEditor] : [BlockEditor]),
    [props.allowDelete],
  );

  const { selectedBlocks, setSelectedBlocks, defaultSelectedBlocks } =
    useContext(SelectedBlocksContext);
  if (
    block.require?.maximumBuildNumberToDisplay &&
    block.require.maximumBuildNumberToDisplay < 9999999
  ) {
    return null;
  }

  return (
    <Editable
      className="bg-newBackgroundColor"
      ViewerComponent={BlockWithTimestamps}
      editorComponents={editorComponents}
      value={block}
      selectedBlocks={selectedBlocks}
      setSelectedBlocks={setSelectedBlocks}
      defaultSelectedBlocks={defaultSelectedBlocks}
      {...props}
    />
  );
};

const nonEditablePreviewBlockTypes = ["Header", "Divider", "ViewStory", "Card"];
const editableCardBlockTypes = ["AgentCallout"];

const DraggableEditableBlock = ({
  automatedDayDisabled,
  draggableId,
  draggableIndex,
  draggableBlocksStartIndex,
  draggableBlocks,
  allBlocks,
  onChange,
  readonly,
  isFixedFormat,
  editingIndex,
  setEditingIndex,
  isDraggingOver,
}) => {
  const {
    handleBlockClicked,
    resetSelection,
    handleCloneClicked,
    handleDeleteClicked,
    handlePasteClicked,
    isBlockSelected,
  } = useContext(SelectedBlocksContext);

  const getClosestSectionHeaderStartDate = (currentBlock) => {
    let sectionHeaderDate;
    for (const block of allBlocks) {
      if (block.type === "Group" && block.info.kind === "SectionHeader") {
        const dateHeaderBlock = block.info.blocks.find(
          ({ type }) => type === "DateHeader",
        );
        sectionHeaderDate = dateHeaderBlock?.info?.startDate;
      }
      if (block.uuid === currentBlock.uuid) {
        return sectionHeaderDate;
      }
    }
    return undefined;
  };

  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={draggableIndex}
      isDragDisabled={readonly}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {draggableBlocks.map((block, draggableBlocksIndex) => {
            const index = draggableBlocksIndex + draggableBlocksStartIndex;
            const onBlockChange = (newBlock) => {
              // Only a single block changed, no selection change.
              const newBlocks = [...allBlocks];
              newBlocks.splice(index, 1, newBlock);
              onChange(newBlocks);
            };

            const onInsertBlock = () => {
              resetSelection();
              const newBlocks = [...allBlocks];
              newBlocks.splice(index + 1, 0, {
                uuid: uuidv4(),
                type: "Text",
                info: {
                  formattedText: "%CHANGE_ME%",
                },
                ...(block.metadata && { metadata: block.metadata }),
              });
              onChange(newBlocks);
              setEditingIndex(index + 1);
            };

            const onDeleteBlock = () => {
              const blockSelected = isBlockSelected(block);
              if (blockSelected) {
                // eslint-disable-next-line no-alert, no-restricted-globals
                if (
                  !confirm("Are you sure you want to delete this selection?")
                ) {
                  return;
                }
                // eslint-disable-next-line no-alert, no-restricted-globals
              } else if (
                !confirm("Are you sure you want to delete this block?")
              ) {
                return;
              }

              const newBlocks = handleDeleteClicked(allBlocks, index);
              if (newBlocks !== undefined) {
                onChange(newBlocks);
              }
              if (blockSelected || editingIndex === index)
                setEditingIndex(null);
              else if (editingIndex > index) setEditingIndex(editingIndex - 1);
            };

            const onClickBlock = (e) => {
              handleBlockClicked(
                block,
                allBlocks,
                index,
                editingIndex,
                e.shiftKey,
              );
            };

            const onCloneBlock = () => {
              const updatedBlocks = handleCloneClicked(allBlocks, index);
              if (updatedBlocks !== undefined) {
                onChange(updatedBlocks);
              }
            };

            const onPasteBlock = (newBlocks) => {
              const updatedBlocks = handlePasteClicked(
                allBlocks,
                index + 1,
                JSON.parse(newBlocks),
              );
              if (updatedBlocks !== undefined) {
                onChange(updatedBlocks);
              }
            };

            const isEditing = index === editingIndex;
            const setEditing = (editing) => {
              if (editing) {
                setEditingIndex(index);
              } else if (editingIndex === index) {
                setEditingIndex(null);
              }
            };

            const closestSectionHeaderStartDate =
              getClosestSectionHeaderStartDate(block);

            return (
              <MemoizedEditableBlock
                key={`${draggableId}-${index}`}
                block={block}
                allBlocks={allBlocks}
                readonly={
                  readonly ||
                  (isFixedFormat &&
                    nonEditablePreviewBlockTypes.includes(block.type) &&
                    !editableCardBlockTypes.includes(
                      block?.info?.card?.type,
                    )) ||
                  (editingIndex !== null && !isEditing) ||
                  (isDraggingOver && !snapshot.isDragging)
                }
                allowMove={!readonly && !isFixedFormat}
                allowDelete={!isFixedFormat}
                showVisibilityHandle={!readonly && !isFixedFormat}
                onChange={onBlockChange}
                onInsert={onInsertBlock}
                onDelete={onDeleteBlock}
                onClick={onClickBlock}
                onClone={onCloneBlock}
                onPaste={onPasteBlock}
                dragHandleProps={provided.dragHandleProps}
                isEditing={isEditing}
                setEditing={setEditing}
                isDragging={snapshot.isDragging}
                editorParams={{
                  closestSectionHeaderStartDate,
                  automatedDayDisabled,
                }}
              />
            );
          })}
        </div>
      )}
    </Draggable>
  );
};
const MemoizedEditableBlock = React.memo(EditableBlock);
const MemoizedEditableBlocks = React.memo(
  ({
    blocks,
    onChange,
    readonly,
    isFixedFormat,
    editingIndex,
    setEditingIndex,
    isDraggingOver,
    automatedDayDisabled,
  }) => {
    const { computeDraggableObjects } = useContext(SelectedBlocksContext);
    const draggableObjects = computeDraggableObjects(blocks);
    return draggableObjects.map((draggableObject) => {
      return (
        <DraggableEditableBlock
          key={`id-${draggableObject.draggableIndex}`}
          draggableId={`id-${draggableObject.draggableIndex}`}
          draggableIndex={draggableObject.draggableIndex}
          draggableBlocksStartIndex={draggableObject.draggableBlocksStartIndex}
          draggableBlocks={draggableObject.blocks}
          allBlocks={blocks}
          onChange={onChange}
          readonly={readonly}
          isFixedFormat={isFixedFormat}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
          isDraggingOver={isDraggingOver}
          automatedDayDisabled={automatedDayDisabled}
        />
      );
    });
  },
);

MemoizedEditableBlocks.displayName = "MemoizedEditableBlocks";

const EditableBlocks = (props) => {
  const sensorRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const { handleDragEnded } = useContext(SelectedBlocksContext);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedBlocks = handleDragEnded(
      props.blocks,
      result.source,
      result.destination,
    );

    if (updatedBlocks !== undefined) {
      props.onChange(updatedBlocks);
    }
    if (editingIndex === null || editingIndex === undefined) return;
    if (result.source.index === editingIndex)
      setEditingIndex(result.destination.index);
    else if (
      result.source.index < editingIndex &&
      result.destination.index >= editingIndex
    )
      setEditingIndex(editingIndex - 1);
    else if (
      result.source.index > editingIndex &&
      result.destination.index <= editingIndex
    )
      setEditingIndex(editingIndex + 1);
  };

  const { selectedBlocks } = useContext(SelectedBlocksContext);
  const [showMoveBlocks, setShowMoveBlocks] = useState(false);
  const [draggableIndex, setDraggableIndex] = useState();
  const [showUp, setShowUp] = useState(true);
  const [showDown, setShowDown] = useState(true);

  const lift = () => {
    const api = sensorRef.current;

    if (!api) {
      return null;
    }

    const preDrag = api.tryGetLock(`id-${draggableIndex}`);

    if (!preDrag) {
      return null;
    }
    return preDrag.snapLift();
  };

  const moveUp = () => {
    const snap = lift();
    if (snap) {
      snap.moveUp();
      snap.drop();
    }
  };

  const moveDown = () => {
    const snap = lift();
    if (snap) {
      snap.moveDown();
      snap.drop();
    }
  };

  useEffect(() => {
    setShowMoveBlocks(Object.keys(selectedBlocks.uuids).length > 0);
    setDraggableIndex(selectedBlocks.minIndex);
    setShowUp(selectedBlocks.minIndex > 0);
    setShowDown(selectedBlocks.maxIndex < props.blocks.length - 1);
  }, [props.blocks.length, selectedBlocks]);

  return (
    <>
      <div className="bg-newBackgroundColor" style={{ width: 375 }}>
        <div
          className="mx-auto border-t border-separatorColor pb-5"
          style={{ width: "25%" }}
        />
      </div>
      <DragDropContext
        onDragEnd={onDragEnd}
        sensors={[
          (api) => {
            sensorRef.current = api;
          },
        ]}
      >
        <Droppable droppableId="blocks">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="relative"
            >
              <MemoizedEditableBlocks
                editingIndex={editingIndex}
                setEditingIndex={setEditingIndex}
                isDraggingOver={snapshot.isDraggingOver}
                {...props}
              />
              {provided.placeholder}
              {showMoveBlocks && (
                <div
                  className="absolute top-0 h-full"
                  style={{ left: "420px" }}
                >
                  <div className="sticky top-0 right-0 flex gap-1 p-1 items-center font-bold">
                    <span>Move blocks:</span>
                    {showUp && (
                      <EditorIcon
                        icon={upIcon}
                        postHogEvent="clicked_move_blocks_up"
                        onClick={() => moveUp(!snapshot.isDraggingOver)}
                      />
                    )}
                    {showDown && (
                      <EditorIcon
                        icon={downIcon}
                        postHogEvent="clicked_move_blocks_down"
                        onClick={() => moveDown(!snapshot.isDraggingOver)}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="bg-newBackgroundColor p-6" style={{ width: 375 }} />
    </>
  );
};

const automaticallyUpdateDateAndSectionHeader = (trip, newBlocks) => {
  if (trip.automatedDayDisabled) {
    return;
  }
  const sectionHeaders = [];
  const dateHeaderBlockIndex = 1;
  const headerBlockIndex = 2;
  for (let index = 0; index < newBlocks.length; index += 1) {
    const block = newBlocks[index];
    const isSectionHeaderAutomatedValue = isSectionHeaderAutomated(
      block.metadata?.automated,
      block?.info?.blocks?.[headerBlockIndex]?.info?.title,
      block?.info?.blocks?.[dateHeaderBlockIndex]?.info?.startDate,
      block?.info?.blocks?.[dateHeaderBlockIndex]?.info?.endDate,
    );
    if (
      !isSectionHeaderAutomatedValue ||
      block?.type !== "Group" ||
      block?.info?.kind !== "SectionHeader" ||
      block?.info?.blocks?.[dateHeaderBlockIndex]?.type !== "DateHeader" ||
      !block?.info?.blocks?.[dateHeaderBlockIndex]?.info?.startDate ||
      block?.info?.blocks?.[headerBlockIndex]?.type !== "Header"
    ) {
      continue;
    }
    if (isBlockHiddenFromAdmin(block)) {
      // The block is only visible by Admin, we should not count it
      continue;
    }

    if (!isSectionHeaderAutomatedValue) {
      if (
        sectionHeaders.length > 0 &&
        block?.metadata?.automated === undefined
      ) {
        sectionHeaders.length = 0;
        // Disabling automation because we have something that should not (not all blocks are flag automated)
        // eslint-disable-next-line no-console
        console.warn(
          "automaticallyUpdateDateAndSectionHeader() disabled because a bad block (not explicitly flagged as not automated):",
          block,
        );
        break;
      }
    }

    const blockStartDate = moment(
      block.info.blocks[dateHeaderBlockIndex].info.startDate,
    );
    const blockEndDate = moment(
      block.info.blocks[dateHeaderBlockIndex].info.endDate,
    );
    const blockDays = blockEndDate.diff(blockStartDate, "days");

    sectionHeaders.push({
      block,
      sourceIndex: index,
      blockStartDate,
      blockEndDate,
      blockDays,
    });
  }

  if (sectionHeaders.length > 0) {
    const startDate = trip.startDate
      ? moment(trip.startDate)
      : moment(
          Math.min(
            ...sectionHeaders.map((sh) =>
              moment(sh.block.info.blocks[1].info.startDate).getTime(),
            ),
          ),
        );
    sectionHeaders.forEach((sectionHeaderItem, index) => {
      const { block, blockStartDate, blockEndDate, blockDays, sourceIndex } =
        sectionHeaderItem;
      const description = getSectionHeaderAutomatedTitle(
        block.info.blocks[2].info.title,
      );

      const previousBlock = sectionHeaders[index - 1]?.block;
      let updatedStartDate = blockStartDate;
      let updatedEndDate = blockEndDate;

      if (previousBlock) {
        const previousBlockEndDate = moment(
          previousBlock.info.blocks[dateHeaderBlockIndex].info.endDate,
        );
        const daysSincePreviousBlockEndDate = blockStartDate.diff(
          previousBlockEndDate,
          "days",
        );
        if (
          previousBlockEndDate.isAfter(blockStartDate) ||
          daysSincePreviousBlockEndDate >= 0
        ) {
          updatedStartDate = previousBlockEndDate.clone().add(1, "days");
          updatedEndDate = previousBlockEndDate
            .clone()
            .add(blockDays + 1, "days");
        }
      } else {
        updatedStartDate = startDate;
        updatedEndDate = startDate.clone().add(blockDays, "days");
      }
      const daysSinceTripStartDate =
        moment(updatedStartDate).diff(startDate, "days") + 1;

      const updatedBlock = produce(block, (draft) => {
        draft.info.blocks[dateHeaderBlockIndex].info.startDate =
          updatedStartDate.format("YYYY-MM-DD");
        draft.info.blocks[dateHeaderBlockIndex].info.endDate =
          updatedEndDate.format("YYYY-MM-DD");
        draft.info.blocks[headerBlockIndex].info.title = `Day ${
          blockDays > 0
            ? `${daysSinceTripStartDate} - ${daysSinceTripStartDate + blockDays}`
            : daysSinceTripStartDate
        }: ${description}`;
      });
      sectionHeaders[index].block = updatedBlock;
      newBlocks[sourceIndex] = updatedBlock;
    });
  }
};

export const TripDisplay = ({
  tripRequestId,
  tripReference,
  isStory,
  isBlueprintable,
}) => {
  const [trip, tripLoading] = useDocumentData(tripReference);

  const isDraft = tripReference.path.startsWith("draft");

  const blockContainerReference = useMemo(() => {
    if (isStory) {
      if (isDraft) return doc(collection(tripReference, "story"), "blueprint");
      return doc(collection(tripReference, "story"), "current");
    }
    if (isBlueprintable && isDraft)
      return doc(collection(tripReference, "preview"), "blueprint");
    return doc(collection(tripReference, "preview"), "current");
  }, [isDraft, isStory, isBlueprintable, tripReference]);
  const [blockContainer, blockContainerLoading] = useDocumentData(
    blockContainerReference,
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (tripRequestId && !tripLoading && !trip) {
      navigate({
        pathname: `/tripRequests/${tripRequestId}`,
        search: location.search,
      });
    }
  }, [tripLoading, trip, navigate, tripRequestId, location.search]);

  const [blocks, setBlocks] = useState([]);
  useEffect(() => {
    if (blockContainer) setBlocks(blockContainer.blocks);
  }, [blockContainer]);

  if (!trip) return null;

  const oldFormat =
    !isBlueprintable && !blockContainerLoading && (!blockContainer || !blocks);

  const setBlocksAndUpdateStorage = (newTrip, newBlocks) => {
    automaticallyUpdateDateAndSectionHeader(newTrip, newBlocks);
    setBlocks(newBlocks);
    setDoc(blockContainerReference, { blocks: newBlocks }, { merge: true });
  };

  return (
    <div className="p-3 overflow-y-auto">
      <EditableTripHeader
        trip={trip}
        hideIntro={isStory}
        onChange={(newTrip) => {
          if (trip.startDate !== newTrip.startDate) {
            // start date has changed... Ensuring we update the blocks too for date change
            setBlocksAndUpdateStorage(newTrip, [...blocks]);
          }
          setDoc(tripReference, newTrip);
        }}
        readonly={!isDraft}
      />
      {oldFormat && (
        <div
          className="bg-newBackgroundColor p-3 font-bold text-sm text-mainTintColor"
          style={{ width: 375 }}
        >
          ERROR: Old story format, unable to render
        </div>
      )}
      {!blockContainerLoading && !oldFormat && blocks && (
        <GalleryAspectContext.Provider
          value={isBlueprintable ? undefined : previewGalleryAspect}
        >
          <TripRequestIdContext.Provider value={tripRequestId}>
            <SelectedBlocksProvider>
              <EditableBlocks
                blocks={blocks}
                onChange={(newBlocks) => {
                  setBlocksAndUpdateStorage(trip, newBlocks);
                }}
                readonly={!isDraft}
                isFixedFormat={!isBlueprintable}
                automatedDayDisabled={trip.automatedDayDisabled}
              />
            </SelectedBlocksProvider>
          </TripRequestIdContext.Provider>
        </GalleryAspectContext.Provider>
      )}
    </div>
  );
};
