import React, { useMemo } from "react";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";

import { faClock as timeIcon } from "@fortawesome/free-solid-svg-icons/faClock";
import { faExclamationCircle as issueIcon } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faFile as fileIcon } from "@fortawesome/free-solid-svg-icons/faFile";
import { faUser as userIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { collection, doc } from "firebase/firestore";
import { firestore, functions } from "../../firebase";
import { getLocalTime } from "../../helpers/time";
import { NotionIcon } from "../NotionIcon";

const TemplateImportInfo = ({ template: { id: templateId, importInfo } }) => {
  const reference = useMemo(
    () => doc(collection(firestore, "profiles"), importInfo.userId),
    [importInfo.userId],
  );
  const [profile] = useDocumentDataOnce(reference);

  const issues = importInfo.issues || [];

  return (
    <div className="flex flex-col bg-cardBackgroundColor p-3 rounded shadow text-sm">
      <div className="flex items-center mb-1">
        <NotionIcon className="w-5 h-5 mr-1" />
        <h2 className="text-lg font-semibold">{importInfo.title || ""}</h2>
      </div>
      <a
        href={`https://www.notion.so/origindot/${templateId}`}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        Open in Notion
      </a>
      <div className="font-semibold mt-2 mb-1">Last upload:</div>
      <div className="text-sm">
        <FontAwesomeIcon icon={userIcon} fixedWidth />
        <span className="ml-1">{profile?.displayName}</span>
      </div>
      <div className="text-sm">
        <FontAwesomeIcon icon={timeIcon} fixedWidth />
        <span className="ml-1">
          {getLocalTime(importInfo.time).format("YYYY-MM-DD HH:mm")}
        </span>
      </div>
      <div className="text-sm">
        <FontAwesomeIcon icon={fileIcon} fixedWidth />
        <span className="ml-1">{importInfo.file}</span>
      </div>
      {issues.length > 0 && (
        <>
          <div className="font-semibold mt-2">{issues.length} issues:</div>
          {issues.map((issue, index) => (
            <div
              key={new Buffer(index).toString("base64")}
              className="flex items-start p-2 mt-2 bg-regularButtonBackgroundColor rounded"
            >
              <FontAwesomeIcon
                icon={issueIcon}
                fixedWidth
                className="mt-1 mr-1 text-mainTintColor"
              />
              <div
                className="overflow-hidden"
                style={{ overflowWrap: "break-word" }}
              >
                {issue}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const PublishInspiration = ({ template: { id: templateId, tripInfo } }) => {
  const reference = useMemo(
    () =>
      doc(
        collection(firestore, "sharedInspirationEvents"),
        `inspiration_${templateId}`,
      ),
    [templateId],
  );
  const [inspirationEvent, loading] = useDocumentData(reference);

  if (loading) return null;

  const handlePublish = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!confirm(`Publish "${tripInfo.title}" to all users?`)) return;

    (async () => {
      try {
        await functions.httpsCallable("processInspirationCommand")({
          command: "PublishInspiration",
          params: { inspirationId: templateId },
        });
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      }
    })();
  };

  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
      <h2 className="text-lg font-semibold mb-2">The edit</h2>
      {inspirationEvent ? (
        <div>
          Published to <em>The edit</em> at{" "}
          {inspirationEvent.time?.date
            ? getLocalTime(inspirationEvent.time).format("YYYY-MM-DD HH:mm")
            : ""}
          .
        </div>
      ) : (
        <>
          <div>
            Current status: not yet published to <em>The edit</em>.
          </div>
          <button
            type="button"
            className="bg-regularButtonBackgroundColor mt-3 py-2 rounded w-full text-sm font-bold"
            onClick={handlePublish}
          >
            Publish to all users
          </button>
        </>
      )}
    </div>
  );
};

export const TemplateInfo = ({ selectedTemplate }) => {
  return (
    <div className="flex-grow p-3 overflow-y-auto ">
      {selectedTemplate?.id ? (
        <>
          <TemplateImportInfo template={selectedTemplate.data()} />
          {selectedTemplate.type === "INSPIRATION" && (
            <PublishInspiration template={selectedTemplate} />
          )}
        </>
      ) : (
        <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
          No template selected...
        </div>
      )}
    </div>
  );
};
