import React, { useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { firestore } from '../../firebase';

export const AutoTriageToggle = ({ userId, className, ...props }) => {
  const reference = useMemo(() => doc(firestore, 'users', userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);
  const flagOverrides = loading || error || !user?.flagOverrides ? {} : user.flagOverrides;

  const autoTriageTurnedOffForUser = flagOverrides.autoTriage !== undefined;
  const label = autoTriageTurnedOffForUser ? 'Auto Triage turned off for user' : 'Default Auto Triage';

  const handleToggle = async () => {
    const updatedFlagOverrides = { ...flagOverrides };
    if (autoTriageTurnedOffForUser) {
      delete updatedFlagOverrides.autoTriage;
    } else {
      updatedFlagOverrides.autoTriage = false;
    }
    await setDoc(reference, { flagOverrides: updatedFlagOverrides }, { merge: true });
  };

  return (
    <div className={`${className || ''} flex items-center`} {...props}>
      <input
        className="w-0 h-0"
        style={{ visibility: 'hidden' }}
        id="autoTriageToggle"
        type="checkbox"
        checked={autoTriageTurnedOffForUser}
        onChange={handleToggle}
      />
      <label
        className={`flex items-center transition-colors duration-100 ease-out ${
          autoTriageTurnedOffForUser ? 'bg-coloredButtonForegroundColor' : 'bg-dividerColor'
        } w-12 h-6 rounded-full cursor-pointer`}
        htmlFor="autoTriageToggle"
      >
        <span
          className="bg-cardBackgroundColor h-0 w-0 rounded-full transition-transform duration-100 ease-out"
          style={{
            paddingTop: '42%',
            paddingLeft: '42%',
            marginLeft: '4%',
            marginRight: '4%',
            ...(autoTriageTurnedOffForUser && { transform: 'translate(119%, 0)' }),
          }}
        />
      </label>
      {label && (
        <label htmlFor="autoTriageToggle" className="text-sm ml-1 leading-none">
          {label}
        </label>
      )}
    </div>
  );
};
