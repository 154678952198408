import { cx } from '@emotion/css';
import type { InAppPaymentBlock as InAppPaymentBlockType } from '@origin-dot/core';
import { formatCurrency } from '@origin-dot/core';
import currencyLib from 'currency.js';

import { BlockContainer } from '../common';
import { OriginRichText } from '../common/OriginRichText';
import { OriginText } from '../common/OriginText';
import { BlockComponentProps } from '../helpers/types';

/** Payment Block that allows for inAppPayments. */
export const InAppPaymentBlock = ({
  className,
  title,
  amountLabel,
  formattedMessage,
  amount,
  currency,
  buttonText,
  dueDate,
  status,
  hostedInvoiceUrl = 'https://scenset.com/page-not-found',
}: BlockComponentProps<InAppPaymentBlockType>) => {
  const utcDate = new Date(dueDate).toUTCString();
  return (
    <BlockContainer extended className={className}>
      <div
        className={cx(className, 'shadow-card')}
        css={{
          backgroundColor: 'var(--cardElevatedPrimaryColor)',
          borderRadius: 12,
          padding: 16,
        }}
      >
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          ]}
        >
          <OriginText className="type-title3 text-primary" css={{ marginBottom: 8 }} text={title} />
          <div className="bg-generalTintColor text-generalTextPrimaryColor rounded-full px-3 py-2 text-xs font-bold">
            {status}
          </div>
        </div>
        <OriginRichText className="type-body text-secondary" text={formattedMessage} />
        <div />
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderColor: 'var(--separatorColor)',
              marginLeft: -16,
              marginRight: -16,
              padding: '12px 16px',
              borderBottomWidth: 1,
              borderTopWidth: 1,
              marginTop: 12,
            },
          ]}
        >
          <div className="type-subhead text-primary">
            <OriginText text={amountLabel} />
          </div>
          <div className="type-subhead text-primary">
            {formatCurrency(currencyLib(amount, { fromCents: true }).value, currency)}
          </div>
        </div>
        {/* This is essentially a link styled manually as a button */}
        <a href={hostedInvoiceUrl} target="_blank" rel="noreferrer">
          <div
            className="type-headline text-primary font-bold"
            css={{
              width: '100%',
              margin: '16px 0',
              padding: '16px',
              textAlign: 'center',
              height: '50px',
              borderRadius: '6px',
              backgroundColor: 'var(--buttonSecondaryColor)',
              '&:hover': {
                backgroundColor: 'var(--buttonSecondaryHoverColor)',
                transition: 'background-color .3s ease',
              },
            }}
          >
            <OriginText text={buttonText} />
          </div>
        </a>
        <div className="type-caption1Regular text-secondary">
          Payment due {utcDate}
          <p>
            By making a payment, you acknowledge that you have read and agreed to{' '}
            <a
              href="https://scenset.com/terms-conditions"
              target="_blank"
              rel="noreferrer"
              css={{ color: 'var(--generalTintColor)' }}
            >
              terms and conditions
            </a>{' '}
            and{' '}
            <a
              href="https://scenset.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              css={{ color: 'var(--generalTintColor)' }}
            >
              privacy policy
            </a>
            .
          </p>
        </div>
      </div>
    </BlockContainer>
  );
};
