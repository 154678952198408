import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MapboxClient, {
  DirectionsService,
} from "@mapbox/mapbox-sdk/services/directions";
import { MapboxStyle } from "@origin-dot/core";
import mapboxgl, {
  MapOptions,
  FullscreenControl,
  LngLatBoundsLike,
  Map,
  NavigationControl,
} from "mapbox-gl";

import { WithChildren } from "../helpers/types";

type MapboxContextType = {
  accessToken: string;
  hideControls?: boolean;
};

const MapboxContext = createContext<MapboxContextType | undefined>(undefined);

const fitBoundsOptions: MapOptions["fitBoundsOptions"] = {
  padding: 40,
  duration: 0,
};

export type UpdateFunction = (
  map: Map,
  directions: DirectionsService | undefined,
  style: MapboxStyle,
) => Promise<void>;

export const useMapbox = (
  style: MapboxStyle,
  bounds: LngLatBoundsLike,
  update: UpdateFunction,
) => {
  const [map, setMap] = useState<Map | undefined>();
  const [mapboxSupported, setMapboxSupported] = useState<boolean>(
    mapboxgl.supported(),
  );
  const ref = useRef<HTMLDivElement>(null);

  const context = useContext(MapboxContext);
  if (!context) {
    throw new Error("Missing Mapbox access token");
  }
  const { accessToken, hideControls } = context;

  const directions = useMemo<DirectionsService | undefined>(() => {
    if (!mapboxSupported) {
      return undefined;
    }
    return MapboxClient({ accessToken });
  }, [accessToken, mapboxSupported]);

  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    if (mapboxgl.supported()) {
      const newMap = new Map({
        accessToken,
        container: ref.current,
        style: style.url && `${style.url}?optimize=true`,
        bounds,
        fitBoundsOptions,
        scrollZoom: false,
        fadeDuration: 0,
      });

      if (!hideControls) {
        newMap.addControl(new FullscreenControl());
        newMap.addControl(new NavigationControl({ showCompass: false }));
      }

      setMap(newMap);

      return () => newMap.remove();
    }

    setMapboxSupported(false);
    setMap(undefined);

    return undefined;

    // Bounds & style.url are left out of dependencies on purpose; they're handled dynamically below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, hideControls]);

  // Keep this one near the top.
  useEffect(() => {
    if (map) {
      map.setStyle((style.url && `${style.url}?optimize=true`) ?? "");
    }
  }, [map, style]);

  useEffect(() => {
    if (!map) {
      return undefined;
    }

    update(map, directions, style).catch(() => {});

    const onLoad = () => {
      update(map, directions, style).catch(() => {});
    };

    map.on("styledata", onLoad);
    return () => {
      map.off("styledata", onLoad);
    };
  }, [directions, map, style, update]);

  useEffect(() => {
    if (map) {
      map.fitBounds(bounds, fitBoundsOptions);
    }
  }, [map, bounds]);

  const resize = useCallback(() => {
    if (map) {
      map.resize();
      map.fitBounds(bounds, fitBoundsOptions);
    }
  }, [bounds, map]);

  return { ref, map, directions, resize };
};

type MapboxProviderProps = WithChildren<{
  accessToken: string;
  hideControls?: boolean;
}>;

export const MapboxProvider = ({
  accessToken,
  hideControls = false,
  ...props
}: MapboxProviderProps) => {
  const context = useMemo<MapboxContextType>(
    () => ({ accessToken, hideControls }),
    [accessToken, hideControls],
  );
  return <MapboxContext.Provider value={context} {...props} />;
};
