import React, { useMemo, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, collection } from 'firebase/firestore';

import { firestore } from '../firebase';
import { getCountryNameForIsoCode } from '../helpers/country';


const ProfileDetails = ({ item }) => {
  const { info } = item;
  const { answer } = info;
  const { allowContactByPhone, email, firstName, lastName, phone } = answer;
  const keyClassName = 'text-sm font-medium text-gray-500';
  const valueClassName = 'mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 italic';
  return (
    <div className="bg-gray-50 px-4 py-5 sm:gap-4 sm:px-6">
      <dt className={keyClassName}>First Name</dt>
      <dd className={valueClassName}>{firstName}</dd>
      <dt className={keyClassName}>Last Name</dt>
      <dd className={valueClassName}>{lastName}</dd>
      <dt className={keyClassName}>Email</dt>
      <dd className={valueClassName}>{email}</dd>
      <dt className={keyClassName}>Phone number</dt>
      <dd className={valueClassName}>{phone}</dd>
      <dt className={keyClassName}>Can contact by phone</dt>
      <dd className={valueClassName}>{`${allowContactByPhone ? 'Yes' : 'No'}`}</dd>
    </div>
  );
};

const TripInfo = ({ item }) => {
  const {
    title,
    dateMode,
    startDate,
    endDate,
    flexibleNumberOfDays,
    flexibleDateText,
    countries,
    numberOfAdults,
    numberOfTeenagers,
    numberOfChildren,
    numberOfInfants,
    numberOfRooms,
  } = item.info.answer;
  const keyClassName = 'text-sm font-medium text-gray-500';
  const valueClassName = 'mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 italic';
  return (
    <div className="bg-gray-50 px-4 py-5 sm:gap-4 sm:px-6">
      <dt className={keyClassName}>Title</dt>
      <dd className={valueClassName}>{title}</dd>
      <dt className={keyClassName}>Dates</dt>
      <dd className={valueClassName}>
        {dateMode === 'specific'
          ? `${startDate} - ${endDate}`
          : `${flexibleNumberOfDays} days/${flexibleNumberOfDays - 1} nights in ${flexibleDateText}`}
      </dd>
      <dt className={keyClassName}>Destination Countries</dt>
      <dd className={valueClassName}>{countries.map(i => getCountryNameForIsoCode(i)).join(', ')}</dd>
      <dt className={keyClassName}>Number of adults</dt>
      <dd className={valueClassName}>{numberOfAdults || '0'}</dd>
      <dt className={keyClassName}>Number of teenagers</dt>
      <dd className={valueClassName}>{numberOfTeenagers || '0'}</dd>
      <dt className={keyClassName}>Number of children</dt>
      <dd className={valueClassName}>{numberOfChildren || '0'}</dd>
      <dt className={keyClassName}>Number of infants</dt>
      <dd className={valueClassName}>{numberOfInfants || '0'}</dd>
      <dt className={keyClassName}>Number of rooms</dt>
      <dd className={valueClassName}>{numberOfRooms || '1'}</dd>
    </div>
  );
};

const TripBudget = ({ item }) => {
  const { value, currency } = item.info.answer;
  return (
    <div className="bg-gray-50 px-4 py-5 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{item.title}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 italic">{`${value} ${currency}`}</dd>
    </div>
  );
};

const QuestionAndAnswer = ({ item }) => {
  const { title, info, type } = item;
  if (!info) return null;
  if (!info.answer) return null;

  if (type === 'profileDetails') {
    return <ProfileDetails item={item} />;
  }
  if (type === 'tripRequestData') {
    return <TripInfo item={item} />;
  }
  if (type === 'tripRequestBudget') {
    return <TripBudget item={item} />;
  }

  const answerList = Array.isArray(info.answer) ? info.answer : [info.answer];

  const rawAnswers =
    info &&
    answerList.map(
      answer =>
        (info.options &&
          info.options.filter(option => option.value === answer).map(option => option.formattedTitle)[0]) ||
        answer
    );

  // eslint-disable-next-line prefer-spread
  const answer = [].concat.apply([], rawAnswers).join(';\n');

  return (
    <div className="bg-gray-50 px-4 py-5 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 italic">{answer}</dd>
    </div>
  );
};

const TripRequestQuestionnaire = ({ tripRequestQuestionnaire, initialCollapse = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapse);
  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div>
        <button
          className="text-lg font-semibold"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ textAlign: 'left' }}
        >
          <h2>Trip Questionnaire{!isCollapsed && ` (Trip ${tripRequestQuestionnaire.version})`}</h2>
        </button>
        {!isCollapsed && (
          <div>
            <dl>
              {tripRequestQuestionnaire.items.map(item => (
                <QuestionAndAnswer item={item} key={item.identifier} />
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export const OnboardingQuestionnaire = ({ userId, initialCollapse = false }) => {
  const questionnaireReference = useMemo(
    () =>
      doc(
        collection(
          doc(
            collection(
              doc(collection(firestore, 'users'), userId),
              'questionnaires'
            ),
            'onboarding'
          ),
          'submissions'
        ),
        'current'
      ),
    [userId]
  );

  const [questionnaireSnapshot] = useDocument(questionnaireReference);

  const [isCollapsed, setIsCollapsed] = useState(initialCollapse);
  const questionnaireData = questionnaireSnapshot && questionnaireSnapshot.exists() && questionnaireSnapshot.data();

  if (!questionnaireData) return null;

  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div>
        <button
          className="text-lg font-semibold"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ textAlign: 'left' }}
        >
          <h2>Onboarding Questionnaire{!isCollapsed && ` (Onboarding ${questionnaireData.version})`}</h2>
        </button>
        {!isCollapsed && (
          <div>
            <dl>
              {questionnaireData.items.map(item => (
                <QuestionAndAnswer item={item} key={item.identifier} />
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

const UserProfileQuestionnaire = ({ userId, initialCollapse = false }) => {
  const questionnaireReference = useMemo(
    () =>
      doc(
        collection(
          doc(
            collection(
              doc(collection(firestore, 'users'), userId),
              'questionnaires'
            ),
            'profile'
          ),
          'submissions'
        ),
        'current'
      ),
    [userId]
  );

  const [isCollapsed, setIsCollapsed] = useState(initialCollapse);

  const [questionnaireSnapshot] = useDocument(questionnaireReference);
  const questionnaireData = questionnaireSnapshot && questionnaireSnapshot.exists() && questionnaireSnapshot.data();

  if (!questionnaireData) return null;

  const userFullName = questionnaireData.items
    .filter(item => item.identifier === 'profile_details')
    .map(item => {
      const { firstName, lastName } = item.info.answer;
      return firstName && `${firstName}${lastName ? ` ${lastName}` : ''}`;
    })[0];

  const userIdentifier = userFullName || `User:${userId}`;

  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div>
        <button
          className="text-lg font-semibold"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ textAlign: 'left' }}
        >
          <h2>
            {`${userIdentifier}'s Questionnaire`}
            {!isCollapsed && ` (Profile ${questionnaireData.version})`}
          </h2>
        </button>
        {!isCollapsed && (
          <div>
            <dl>
              {questionnaireData.items.map(item => (
                <QuestionAndAnswer item={item} key={item.identifier} />
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export const TripRequestQuestionnaires = ({ tripRequestQuestionnaire, tripRequestSnapshot }) => {
  const inspirationPath =
    tripRequestSnapshot && tripRequestSnapshot.exists() && tripRequestSnapshot.get('inspirationPath');
  const inspirationId = inspirationPath && inspirationPath.split('/')[1];
  const inspirationReference = useMemo(() => doc(collection(firestore, 'inspirations'), inspirationId), [inspirationId]);
  const [inspirationSnapshot] = useDocument(inspirationReference);
  const inspirationData = inspirationSnapshot && inspirationSnapshot.exists() && inspirationSnapshot.data();
  const inspirationTitle = inspirationData && inspirationData.title;
  const inspirationLink = inspirationData && inspirationData.shareUrl;
  const userIds = tripRequestSnapshot.get('users') || [];

  return (
    <div className="flex-grow p-3 overflow-scroll">
      {inspirationTitle && (
        <div className="flex flex-col mt-3 p-3 bg-cardBackgroundColor rounded">
          <a href={inspirationLink}>
            <b>Inspired By: {inspirationTitle}</b>
          </a>
        </div>
      )}
      <TripRequestQuestionnaire tripRequestQuestionnaire={tripRequestQuestionnaire} />
      {userIds.map(userId => (
        <div key={userId}>
          <UserProfileQuestionnaire userId={userId} />
          <OnboardingQuestionnaire userId={userId} />
        </div>
      ))}
    </div>
  );
};
