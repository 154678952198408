import { collection, doc } from "firebase/firestore";
import React, { useEffect, useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { firestore } from "../../firebase";
import { WordCountDiffProvider } from "../../hooks/useWordCountDiff";

import { ColumnTitle } from "../ColumnTitle";
import { ImageCropProvider } from "../ImageCropProvider";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";

import { TripRequestQuestionnaires } from "../Questionnaires";
import { useAuth } from "../auth/useAuth";
import { TripRequestChat } from "../wysiwyg/TripRequestChat";
import { TripDisplay } from "../wysiwyg/shared";
import { TripRequestFeed } from "./TripRequestFeed";
import { TripRequestInfo } from "./TripRequestInfo";

export const TripRequestPage = ({ isSidebarOpen }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isDraft = query.has("draft");

  const { tripRequestId, view } = useParams();
  let { tripId } = useParams();
  const isOnQuestionnaires = tripId === "questionnaires";
  if (isOnQuestionnaires) tripId = undefined;
  const isOnStory = view === "story";

  const liveReference = useMemo(
    () => doc(firestore, "tripRequests", tripRequestId),
    [tripRequestId],
  );
  const draftReference = useMemo(
    () => doc(firestore, "draftTripRequests", tripRequestId),
    [tripRequestId],
  );
  const tripRequestQuestionnaireReference = useMemo(
    () =>
      doc(
        collection(
          doc(firestore, "tripRequests", tripRequestId),
          "questionnaireSubmissions",
        ),
        "current",
      ),
    [tripRequestId],
  );
  const userOOOChatSettingsReference = useMemo(
    () =>
      doc(
        collection(doc(firestore, "users", auth.userId), "oooChatSettings"),
        "current",
      ),
    [auth.userId],
  );

  const [
    liveTripRequestSnapshot,
    liveTripRequestLoading,
    liveTripRequestError,
  ] = useDocument(liveReference);
  const [
    draftTripRequestSnapshot,
    draftTripRequestLoading,
    draftTripRequestError,
  ] = useDocument(draftReference);
  const [tripRequestQuestionnaireSnapshot] = useDocument(
    tripRequestQuestionnaireReference,
  );
  const [userOOOChatSettingsSnapshot] = useDocument(
    userOOOChatSettingsReference,
  );

  useEffect(() => {
    if (
      isDraft &&
      draftTripRequestSnapshot &&
      !draftTripRequestSnapshot.exists()
    ) {
      // We don't have a draft (anymore) so redirect to client view.
      navigate({ search: "" }, { replace: true });
    }
  }, [navigate, isDraft, draftTripRequestSnapshot]);

  const tripRequestSnapshot =
    isDraft && draftTripRequestSnapshot && draftTripRequestSnapshot.exists()
      ? draftTripRequestSnapshot
      : liveTripRequestSnapshot;

  const tripReference = useMemo(
    () =>
      tripId && tripRequestSnapshot
        ? doc(collection(tripRequestSnapshot.ref, "trips"), tripId)
        : null,
    [tripRequestSnapshot, tripId],
  );

  const tripRequestQuestionnaireData =
    tripRequestQuestionnaireSnapshot?.exists() &&
    tripRequestQuestionnaireSnapshot.data();

  if (liveTripRequestLoading || draftTripRequestLoading) {
    return <div>Loading...</div>;
  }

  if (liveTripRequestError || draftTripRequestError) {
    console.error("Live Trip Request Error:", liveTripRequestError);
    console.error("Draft Trip Request Error:", draftTripRequestError);
    return <div>Error loading trip request. Please try again.</div>;
  }

  if (!liveTripRequestSnapshot || !draftTripRequestSnapshot) return null;

  let mainTitle;
  if (tripId) {
    mainTitle = isOnStory ? "Full Story" : "Trip Preview";
  } else {
    mainTitle = "Trip Request Feed";
  }

  return (
    <WordCountDiffProvider>
      <PageTitle title="Trip Request" />
      <Layout
        className="flex-grow"
        hasCollapsibleSidebar
        isSidebarOpen={isSidebarOpen}
        titleComponent={ColumnTitle}
      >
        <LayoutColumn relativeWidth={1} title="Trip Request">
          <TripRequestInfo
            tripRequestSnapshot={tripRequestSnapshot}
            tripReference={tripReference}
            hasDraft={draftTripRequestSnapshot.exists()}
            draftStatus={draftTripRequestSnapshot.data()?.status}
            liveStatus={liveTripRequestSnapshot.data()?.status}
            hasQuestionnaires={!!tripRequestQuestionnaireData}
            userOOOChatSettingsSnapshot={userOOOChatSettingsSnapshot}
          />
        </LayoutColumn>
        {isOnQuestionnaires && tripRequestQuestionnaireData ? (
          <LayoutColumn
            relativeWidth={4}
            title="Trip Request Questionnaires"
            compactTitle="Questionnaires"
          >
            <TripRequestQuestionnaires
              tripRequestQuestionnaire={tripRequestQuestionnaireData}
              tripRequestSnapshot={tripRequestSnapshot}
            />
          </LayoutColumn>
        ) : (
          <LayoutColumn
            relativeWidth={4}
            title={mainTitle}
            compactTitle="Itinerary"
          >
            <ImageCropProvider>
              {tripId ? (
                <TripDisplay
                  tripRequestId={tripRequestId}
                  tripReference={tripReference}
                  isStory={isOnStory}
                  isBlueprintable={isOnStory}
                />
              ) : (
                <TripRequestFeed
                  tripRequestReference={tripRequestSnapshot.ref}
                />
              )}
            </ImageCropProvider>
          </LayoutColumn>
        )}
      </Layout>
      {tripId && <TripRequestChat tripRequestId={tripRequestId} />}
    </WordCountDiffProvider>
  );
};
