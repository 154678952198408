import React, { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { collection, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";

import { ColumnTitle } from "../ColumnTitle";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";

import { SubstituteUserToggle } from "../user/SubstituteUserToggle";
import { InspirationListing } from "./InspirationListing";
import { InspirationPageItemInfo } from "./InspirationPageItemInfo";

export const InspirationsPage = () => {
  const reference = useMemo(
    () =>
      query(
        collection(firestore, "inspirations"),
        orderBy("createdAt", "desc"),
      ),
    [],
  );
  const [inspirations] = useCollectionData(reference, { idField: "id" });
  const navigate = useNavigate();
  const { inspirationId } = useParams();
  const selectedInspiration =
    inspirationId &&
    inspirations &&
    inspirations.find(({ id }) => id === inspirationId);

  const onSelectInspirationId = (selectedInspirationId) => {
    navigate(
      `/inspirations${
        selectedInspirationId ? `/${selectedInspirationId}` : ""
      }`,
      { replace: true },
    );
  };

  return (
    <>
      <PageTitle
        title={selectedInspiration ? selectedInspiration.title : "Inspirations"}
      />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={2}
          title={
            <div className="flex items-center">
              <div className="flex-grow">All Inspirations</div>
              <SubstituteUserToggle
                className="font-sans font-normal"
                userId={process.env.PREVIEW_USER_ID}
                label="Preview"
              />
            </div>
          }
          compactTitle="Inspirations"
        >
          <InspirationListing
            inspirations={inspirations}
            selectedInspirationId={inspirationId}
            onSelectInspirationId={onSelectInspirationId}
          />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={1}
          title="Inspiration Info"
          compactTitle="Info"
        >
          <InspirationPageItemInfo selectedInspiration={selectedInspiration} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
