import { ReactNode } from "react";
import Markdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import { css } from "@emotion/react";
import {
  OriginRichText as OriginRichTextType,
  OriginText as OriginTextType,
  originTextToString,
} from "@origin-dot/core";

import { Stylable } from "../helpers/types";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
const parseHtml = htmlParser({
  isValidNode: (node: { type: string }) => node.type === "br",
});

type OriginRichTextProps = Stylable<{ text: OriginRichTextType }>;

// TODO: Fix, idk why it's saying this
// eslint-disable-next-line react/prop-types
export const OriginRichText = ({ text, ...props }: OriginRichTextProps) => {
  return (
    <Markdown
      css={css`
        ul {
          list-style: none;
          margin-left: 0;
          padding-left: 1.4em;
          text-indent: -1.4em;
          margin-bottom: -0.8em;
        }

        li {
          margin-bottom: 0.8em;
        }

        li:before {
          content: '→';
          padding-right: 0.5em;
        }

        a {
          color: var(--generalTintColor);
        }
      `}
      source={originTextToString(text as unknown as OriginTextType) // TODO: Should use locale browser or context override.
        .trim()
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\n/g, "<br>\n")
        .replace(/^[-*] [^\n]*<br>\n<br>\n([^-*]|[-*][^ ])/gm, (substr) =>
          substr.replace("<br>\n<br>\n", "\n\n<br>"),
        )
        .replace(/^[-*] [^\n]*<br>\n([^-*]|[-*][^ ])/gm, (substr) =>
          substr.replace("<br>\n", "\n\n"),
        )}
      escapeHtml={false}
      astPlugins={[parseHtml]}
      renderers={{
        // eslint-disable-next-line react/display-name
        link: ({ href, children }: { href: string; children: ReactNode }) => (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ),
      }}
      {...props}
    />
  );
};
